import { render, staticRenderFns } from "./NewCaseManager.vue?vue&type=template&id=234de693&scoped=true&"
import script from "./NewCaseManager.vue?vue&type=script&lang=js&"
export * from "./NewCaseManager.vue?vue&type=script&lang=js&"
import style0 from "./NewCaseManager.vue?vue&type=style&index=0&id=234de693&prod&scoped=true&lang=css&"
import style1 from "./NewCaseManager.vue?vue&type=style&index=1&id=234de693&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "234de693",
  null
  
)

export default component.exports