<template>
  <v-app>
    <div>
      <b-modal
        id="modal-patient-x-diagnose"
        size="xl"
        title="Pilih Diagnosa"
        hide-footer
      >
        <!-- Tabs -->
        <b-tabs>
          <b-tab title="Daftar Diagnosa" active>
            <div class="mt-5">
              <div class="row">
                <div class="col-md-8">
                  <!-- <Table
                    purpose="modal"
                    :patient_id="patient_id"
                    @updatePatientXDiagnoseTable="getPatientXDiagnose"
                  /> -->

                  <!-- Filter -->
                  <div class="row justify-content-end mt-n3">
                    <div class="col-md-3">
                      <b-input-group>
                        <template #prepend>
                          <b-input-group-text squared>
                            <b-icon-search></b-icon-search>
                          </b-input-group-text>
                        </template>
                        <b-form-input
                          type="text"
                          v-model="filter.name"
                          placeholder="Cari Berdasar Nama Diagnosa"
                          @keyup="filterByName"
                        ></b-form-input>
                      </b-input-group>
                    </div>
                  </div>

                  <!-- Table -->
                  <b-table
                    striped
                    hover
                    class="mt-3"
                    :items="itemList"
                    :fields="fieldList"
                    :style="'white-space: nowrap'"
                    responsive="sm"
                  >
                    <template #table-colgroup="scope">
                      <col
                        v-for="field in scope.fields"
                        :key="field.key"
                        :style="{ width: field.key === 'actions' ? '15%' : '' }"
                      />
                    </template>
                    <template #cell(actions)="data">
                      <b-button
                        size="sm"
                        variant="primary"
                        class="mr-1"
                        @click="savePatientXDiagnose(data.item.id)"
                      >
                        Pilih
                      </b-button>
                    </template>
                  </b-table>
                  <b-pagination
                    
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    aria-controls="my-table"
                    @page-click="pageOnClick"
                    class="mt-4"
                  ></b-pagination>
                </div>
                <div class="col-md-4">
                  <!-- saved diagnoses -->
                  <b-table :items="items" :fields="fields" responsive="sm">
                    <template #table-colgroup="scope">
                      <col
                        v-for="field in scope.fields"
                        :key="field.key"
                        :style="{
                          width: field.key === 'actions' ? '20%' : '',
                          width: field.key === 'title' ? '30%' : '',
                        }"
                      />
                    </template>

                    <template #cell(action)="data">
                      <b-button
                        size="sm"
                        class="mr-1 btn-danger"
                        @click="deletePatientXDiagnosa(data.item.id)"
                        v-b-tooltip.hover
                        title="Hapus"
                        placement="bottom"
                      >
                        <i class="fas fa-trash px-0"></i>
                      </b-button>
                    </template>
                  </b-table>
                </div>
              </div>
            </div>
          </b-tab>
          <b-tab title="Tambah Baru">
            <div class="mt-4">
              <Form :form="formDiagnose" :route="'diagnoses'" purpose="modal" @updateDiagnosaTabelItem="pagination"/>
            </div>
          </b-tab>
        </b-tabs>
      </b-modal>
    </div>
  </v-app>
</template>

<script>
import Form from "@/component/masters/diagnoses/Form.vue";
import Table from "@/component/masters/diagnoses/Table.vue";
import module from "@/core/modules/CrudModule.js";
import Swal from 'sweetalert2';

export default {
  props: {
    patient_id: Number,
    page: String,
  },

  components: {
    Form,
    Table,
  },

  data() {
    return {
      formDiagnose: {
        name: "",
        description: "",
      },

      // array selected item
      arrSelectedId: [],

      items: [],
      fields: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "diagnose_code",
          label: "Kode",
          sortable: true,
        },
        {
          key: "diagnose_name",
          label: "Diagnosa",
          sortable: true,
        },
        {
          key: "action",
          label: "Aksi",
        },
      ],

      // Filter
      filter: {
        name: "",
      },
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 1,
      // Table Head
      fieldList: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "code",
          label: "Kode",
          sortable: true,
        },
        {
          key: "name",
          label: "Nama",
          sortable: true,
        },
        {
          key: "description",
          label: "Deskripsi",
          sortable: true,
        },
        { key: "actions", label: "Aksi" },
      ],
      // Table Items
      itemList: [],
      selectedItemId: [],
      // form
      form: {
        patient_id: "",
        diagnose_id: "",
      },
    };
  },

  methods: {
    async getPatientXDiagnose(patient_id) {
      let route = "patient-x-diagnoses/all";
      let response = await module.paginate(route, `?patient_id=${patient_id}`);

      this.items = response.data;
    },

    async deletePatientXDiagnosa(id) {
      let result = await module.delete("patient-x-diagnoses/" + id);
      console.log(this.patient_id);
      if (result) {
        await this.getPatientXDiagnose(this.patient_id);
        await this.getPatientXDiagnoseId(this.patient_id);
        this.pagination()
        
        this.$emit('updateFormDiagnoseChoosenList',this.patient_id)
      }
    },

    // Table

    pageOnClick(evt, page) {
      evt.preventDefault();
      this.currentPage = page;

      this.pagination();
    },

    async savePatientXDiagnose(diagnose_id) {
      this.form.patient_id = this.patient_id;
      this.form.diagnose_id = diagnose_id;

      // Make Request
      let response = await module.submit(this.form, "patient-x-diagnoses");
      // Check Response
      if (response.state == "error") {
        // Validation Error
        Swal.close()

        Swal.fire('Perhatian','Silahkan Pilih Pasien Terlebih Dahulu','warning')
        
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );

      } else {
        this.selectedItemId = [];
        // update diagnose choosen modal list
        await this.getPatientXDiagnose(this.patient_id);
        // get choosen diagnose Id for except in pagination
        await this.getPatientXDiagnoseId(this.patient_id)
        // update diagnose list content
        await this.pagination();
        // update form diagnose choosen list
        this.$emit("updateFormDiagnoseChoosenList", this.patient_id);
      }
    },

    async getPatientXDiagnoseId(patient_id) {
      this.selectedItemId = [];
      let route = "patient-x-diagnoses";
      let response = await module.paginate(
        route,
        `?patient_id=${patient_id}`
      );

      response.data.forEach((el) => {
        this.selectedItemId.push(el.diagnose_id);
      });
    },

    async pagination() {

      let filterParams = `&selectedItem=${this.selectedItemId}&name=${this.filter.name}`;
      let response = await module.paginate(
        "diagnoses",
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );

      let pagination = response.meta.pagination;
      this.totalRows = pagination.total;

      let a, b;
      b = this.perPage * (this.currentPage - 1) + 1;
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a;
      }

      this.itemList = response.data;
    },

    filterByName() {
      // debounce(() => {
        this.pagination();
      // }, 500);
    },

    // async deleteData(id) {
    //   // Delete Data
    //   let result = await module.delete("diagnoses/" + id);
    //   // If Deleted
    //   if (result) {
    //     this.pagination();
    //   }
    // },

    setActiveButton() {
      let access_right_user = window.localStorage.getItem(
        "access_right_display"
      );
      let access_right = JSON.parse(access_right_user);

      let a;
      for (a = 0; a < access_right.length; a++) {
        if (access_right[a] == "1802") {
          this.btn = true;
        }
      }
    },
  },

  mounted() {
    console.log(this.patient_id, "ID");
    this.getPatientXDiagnose();
    this.pagination();

    // update data when triggered on form
    this.$root.$on('updateModalPatientXDiagnoseFromPage',async (patient_id)=>{
        this.selectedItemId = []
       // update diagnose choosen modal list
        await this.getPatientXDiagnose(patient_id);
        // get choosen diagnose Id for except in pagination
        await this.getPatientXDiagnoseId(patient_id)
        // update diagnose list content
        await this.pagination();
    })
    
    // update data when triggered on form
    this.$root.$on('updateModalPatientXDiagnoseOnSetPatient',async (patient_id)=>{
        this.selectedItemId = []
       // update diagnose choosen modal list
        await this.getPatientXDiagnose(patient_id);
        // get choosen diagnose Id for except in pagination
        await this.getPatientXDiagnoseId(patient_id)
        // update diagnose list content
        await this.pagination();
    })
  },
};
</script>

<style>
</style>