<template>
  <div>
    <div class="d-flex justify-content-end">
      <b-button size="sm" class="btn-success" @click="btnExcelOnClick"
        ><i class="fas fa-file-excel px-0"></i> Export</b-button
      >
    </div>
    <b-table striped class="mt-3" :items="items" :fields="fields">
      <template #table-colgroup="scope">
        <col
          v-for="field in scope.fields"
          :key="field.key"
          :style="{ width: field.key === 'actions' ? '10%' : '' }"
        />
      </template>
    </b-table>
    <b-pagination
      v-model="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      @page-click="pageOnClick"
      class="mt-4"
    ></b-pagination>
  </div>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import XLSX from "xlsx";
import Swal from "sweetalert2";
import moment from "moment";
// import { filter } from "vue/types/umd";

export default {
  data() {
    return {
      filter: {
        start_date: "",
        end_date: "",
        diagnose_id: [],
        patient_name: "",
      },

      // Table Head
      fields: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "diagnose_name",
          label: "Diagnosa",
          sortable: true,
        },
        {
          key: "amount_count_below_or_equal_60",
          label: "Jumlah Pasien <= 60",
          sortable: true,
        },
        {
          key: "amount_count_above_60",
          label: "Jumlah Pasien > 60",
          sortable: true,
        },
        {
          key: "amount",
          label: "Total Pasien",
          sortable: true,
        },
      ],
      // Table Head Excel
      fieldExcel: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "diagnose_name",
          label: "Diagnosa",
          sortable: true,
        },
        {
          key: "amount_count_below_or_equal_60",
          label: "Jumlah Pasien <= 60",
          sortable: true,
        },
        {
          key: "amount_count_above_60",
          label: "Jumlah Pasien > 60",
          sortable: true,
        },
        {
          key: "amount",
          label: "Total Pasien",
          sortable: true,
        },
      ],
      // Table Items
      items: [],
      // Items Excel
      excelItems: [],
      // Pagination
      perPage: 25,
      currentPage: 1,
      totalRows: 1,
      recordData: 0,
    };
  },

  methods: {
    async pageOnClick(evt, page) {
      evt.preventDefault();
      this.currentPage = page;
      await this.pagination();
    },

    async pagination() {
      let filterParams = `&start_date=${this.filter.start_date}&end_date=${this.filter.end_date}&diagnose_id=${this.filter.diagnose_id}&patient_name=${this.filter.patient_name}`;
      let response = await module.paginate(
        "diagnose-patient-amount",
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      let pagination = response.meta.pagination;
      this.totalRows = pagination.total;

      let a, b;
      b = this.perPage * (this.currentPage - 1) + 1;
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a;
      }

      this.items = response.data;
    },

    btnInfoOnClick(d) {},

    setFilterOnClick(data) {
      this.filter.start_date = data.start_date;
      this.filter.end_date = data.end_date;
      this.filter.diagnose_id = data.diagnose_id;
      this.filter.patient_name = data.name;

      this.pagination();
    },

    async btnExcelOnClick() {
      SwalLoading.fire();
      let filterParams = `&start_date=${this.filter.start_date}&end_date=${this.filter.end_date}&diagnose_id=${this.filter.diagnose_id}&patient_name=${this.filter.patient_name}`;
      let response = await module.paginateWithoutNumber(
        "diagnose-patient-amount",
        `?page_size=all${filterParams}`
      );

      this.excelItems = response.data;
      SwalLoading.close();

      const data = XLSX.utils.json_to_sheet(this.excelItems);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, data, "data");
      XLSX.writeFile(
        wb,
        "Daftar Jumlah Pasien Per-Diagnosa (Periode " +
          moment(this.filter.start_date).format("DD-MM-YYYY") +
          " sampai " +
          moment(this.filter.end_date).format("DD-MM-YYYY") +
          ").xlsx"
      );
    },
  },

  mounted() {
    this.pagination();
    this.$root.$on("setFilterDataOnClick", (data) => {
      this.setFilterOnClick(data);
    });
  },
};
</script>

<style>
</style>