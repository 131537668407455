<template>
  <div>
    <b-form @submit.stop.prevent="formOnsubmit">
      <!-- Name input -->
      <b-form-group id="input-group-name" label="Nama:" label-for="input-name">
        <b-form-input
          id="input-name"
          v-model="form.name"
          placeholder="Nama Klasifikasi"
        ></b-form-input>
        <small class="text-danger">{{ error.name }}</small>
      </b-form-group>

      <!-- Description input -->
      <b-form-group id="input-group-description">
        <label for="input-description"
          >Deskripsi: <em class="text-muted">opsional</em></label
        >
        <b-form-textarea
          id="input-description"
          v-model="form.description"
          placeholder="Deksripsi Klasifikasi"
          rows="4"
          max-rows="8"
        ></b-form-textarea>
        <small class="text-danger">{{ error.description }}</small>
      </b-form-group>

      <!-- Submit & Cancel button -->
      <b-button type="submit" variant="primary">Simpan</b-button>
      <b-button
        type="button"
        class="ml-2"
        variant="default"
        @click="$router.push('/masters/classifications')"
      >
        Batal
      </b-button>
    </b-form>
  </div>
</template>

<script>
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";

export default {
  props: {
    form: Object,
    route: String,
    purpose: {
      type: String,
      default: "form"
    },
  },

  data() {
    return {
      // Error
      error: {
        name: "",
        description: "",
      },
    };
  },

  methods: {
    async formOnsubmit() {
      // Make Request
      let response = await module.submit(this.form, this.route);
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, "success");
        if(this.purpose == "form"){
          this.$router.push("/masters/classifications");
        }else{
          this.$emit('updateClassificationTabelItem')
        }
      }
    },
  },
};
</script>

<style>
</style>