<template>
  <div>
    <b-alert
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b"
    >
      <div class="alert-icon">
        <span class="svg-icon svg-icon-lg">
          <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
        </span>
      </div>
      <div class="alert-text">
        Berikut adalah Daftar<b> Rekam Medis Pasien</b> yang ada di Klinik
        Hayandra
      </div>
      <b-button
        v-if="page == 'edit' || page == 'detail'"
        squared
        variant="success"
        @click="handleAdd"
        >Tambah</b-button
      >
    </b-alert>

    <div class="row">
      <div class="col-md-12">
        <!-- <div class="col-md-4"> -->

        <b-alert
          v-if="$route.query.appointment_id && $route.query.doctor_id"
          show
          variant="light"
          class="alert alert-custom alert-white alert-shadow fade show gutter-b"
        >
          <div class="alert-icon">
            <span class="svg-icon svg-icon-lg">
              <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
            </span>
          </div>
          <div class="alert-text">
            Perjanjian
            <strong>{{
              doctors[doctors.findIndex((x) => x.id == $route.query.doctor_id)]
                .label
            }}</strong>
          </div>
          <b-button
            squared
            variant="success"
            @click="
              $router.push(`/appointment/detail/${$route.query.appointment_id}`)
            "
            >Detail</b-button
          >
        </b-alert>

        <Card v-bind:title="'Tambah Rekam Medis'" v-if="page == 'add'">
          <template v-slot:body>
            <div>
              <b-form
                v-if="show"
                @submit.stop.prevent="formAdd"
                enctype="multipart/form-data"
              >
                <b-form-group id="input-group-date">
                  <label for="input-date"
                    >Tanggal: <em class="text-muted">opsional</em></label
                  >
                  <b-input-group>
                    <b-form-datepicker
                      locale="id"
                      placeholder="Tanggal"
                      id="input-date"
                      v-model="date"
                    ></b-form-datepicker>

                    <!-- <b-form-timepicker
                      v-model="time"
                      locale="id"
                      placeholder="Waktu"
                      now-button
                      label-now-button="Waktu Sekarang"
                      reset-button
                      minutes-step="5"
                    ></b-form-timepicker> -->
                  </b-input-group>
                  <small class="text-danger">{{ error.date }}</small>
                </b-form-group>

                <div class="row">
                  <div class="col-12 pb-0">
                    <b-form-group
                      id="input-group-trigger-modal-patient"
                      label="Pilih Pasien :"
                      label-for="input-trigger-modal-patient"
                    >
                      <b-form-input
                        id="input-trigger-modal-patient"
                        v-model="display.patient_name"
                        placeholder="Pilih Pasien"
                        readonly
                        @click="$bvModal.show('modal-patient')"
                      >
                      </b-form-input>
                      <small class="text-danger">{{ error.patient_id }}</small>
                    </b-form-group>
                  </div>
                </div>

                <div class="row">
                  <!-- <div class="col-12 py-0">
                    <b-form-group
                      id="input-group-classification"
                      label="Klasifikasi:"
                      label-for="input-classification"
                    >
                      <b-form-select
                        id="input-classification"
                        v-model="form.classification_id"
                        :options="classifications"
                        placeholder="Klasifikasi"
                      ></b-form-select>
                      <small class="text-danger">{{
                        error.classification_id
                      }}</small>
                    </b-form-group>
                  </div> -->
                  <div class="col-12">
                    <button
                      type="button"
                      class="btn btn-primary btn-block"
                      @click="$bvModal.show('modal-classification-patient')"
                    >
                      Pilih Klasifikasi
                    </button>
                  </div>
                </div>

                <div class="row px-4 mb-3">
                  <div class="col-12 border border-secondary rounded">
                    <span class="font-weight-bolder">Riwayat Klasifikasi:</span>
                    <b-table
                      :items="classification_patients"
                      :fields="field_classification_patients"
                      responsive="sm"
                    >
                      <template #table-colgroup="scope">
                        <col
                          v-for="field in scope.fields"
                          :key="field.key"
                          :style="{
                            width: field.key === 'actions' ? '20%' : '',
                            width: field.key === 'title' ? '30%' : '',
                          }"
                        />
                      </template>

                      <template #cell(action)="data">
                        <b-button
                          size="sm"
                          class="mr-1 btn-danger"
                          @click="deleteClassificationPatient(data.item.id)"
                          v-b-tooltip.hover
                          title="Hapus"
                          placement="bottom"
                        >
                          <i class="fas fa-trash px-0"></i>
                        </b-button>
                      </template>
                    </b-table>
                  </div>
                </div>

                <div class="row px-4">
                  <div
                    class="col-md-12 mb-2 d-flex justify-content-between bg-secondary rounded"
                    v-if="addCollapse == true"
                    @click="addCollapse = false"
                  >
                    <span class="font-weight-bolder"
                      >Riwayat Rekam Medis Sebelumnya :</span
                    >
                    <span class="font-weight-bolder"
                      ><i class="fas fa-chevron-up"></i
                    ></span>
                  </div>

                  <div
                    class="col-md-12 mb-2 d-flex justify-content-between bg-secondary rounded"
                    v-if="addCollapse == false"
                    @click="addCollapse = true"
                  >
                    <span class="font-weight-bolder"
                      >Riwayat Rekam Medis Sebelumnya :</span
                    >
                    <span class="font-weight-bolder"
                      ><i class="fas fa-chevron-down"></i
                    ></span>
                  </div>

                  <div
                    class="col-md-12 mb-4 bg-secondary rounded"
                    v-if="addCollapse == true"
                  >
                    <b-table
                      :items="itemsRecently"
                      :fields="fieldsRecently"
                      responsive="sm"
                    >
                      <template #table-colgroup="scope">
                        <col
                          v-for="field in scope.fields"
                          :key="field.key"
                          :style="{
                            width: field.key === 'actions' ? '20%' : '',
                            width: field.key === 'title' ? '30%' : '',
                          }"
                        />
                      </template>

                      <template #cell(title)="data">
                        <span v-html="data.item.list_title"></span>
                      </template>

                      <template #cell(display_date)="data">
                        <span v-if="data.item.time == '00:00'">{{
                          new Date(data.item.date).toLocaleString("id-ID", {
                            weekday: "long",
                            day: "numeric",
                            month: "numeric",
                            year: "numeric",
                          })
                        }}</span>
                        <span v-if="data.item.time != '00:00'">{{
                          new Date(data.item.date).toLocaleString("id-ID", {
                            weekday: "long",
                            day: "numeric",
                            month: "numeric",
                            year: "numeric",
                            hour: "2-digit",
                            minute: "2-digit",
                          })
                        }}</span>
                      </template>
                    </b-table>
                  </div>

                  <div class="col-md-12 d-flex justify-content-between">
                    <span class="font-weight-bolder">Diagnosa Pasien :</span>
                    <b-button
                      size="sm"
                      variant="primary"
                      class="mr-1"
                      @click="$bvModal.show('modal-patient-x-diagnose')"
                    >
                      <i class="fas fa-plus px-0"></i> Diagnosa
                    </b-button>
                  </div>

                  <div class="col-md-12 mb-4 border border-secondary rounded">
                    <b-table
                      :items="patientXDiagnose"
                      :fields="fieldsPatientXDiagnose"
                      responsive="sm"
                    >
                      <template #table-colgroup="scope">
                        <col
                          v-for="field in scope.fields"
                          :key="field.key"
                          :style="{
                            width: field.key === 'actions' ? '20%' : '',
                            width: field.key === 'title' ? '30%' : '',
                          }"
                        />
                      </template>

                      <template #cell(action)="data">
                        <b-button
                          size="sm"
                          class="mr-1 btn-danger"
                          @click="deletePatientXDiagnosa(data.item.id)"
                          v-b-tooltip.hover
                          title="Hapus"
                          placement="bottom"
                        >
                          <i class="fas fa-trash px-0"></i>
                        </b-button>
                      </template>
                    </b-table>
                  </div>
                </div>

                <b-form-group id="input-group-title">
                  <label for="textarea">Uraian: </label>
                  <div class="document-editor">
                    <ckeditor
                      id="input-title"
                      :editor="editor"
                      :config="editorConfig"
                      @ready="onReady"
                      v-model="form.title"
                      v-if="addCkEditor"
                    ></ckeditor>
                  </div>
                  <small class="text-danger">{{ error.title }}</small>
                </b-form-group>
                <!-- <b-form-group id="input-group-title">
                  <label for="textarea">Uraian:</label>
                  <b-form-textarea
                    id="input-title"
                    v-model="form.title"
                    placeholder=""
                    rows="3"
                    max-rows="6"
                    required
                  ></b-form-textarea>
                  <small class="text-danger">{{ error.title }}</small>
                </b-form-group> -->

                <!-- <b-form-group id="input-group-case">
                  <label for="textarea"
                    >Instruksi/Tindakan:
                    <em class="text-muted">opsional</em></label
                  >
                  <div class="document-editor">
                    <ckeditor
                      id="input-title"
                      :editor="editor"
                      :config="editorConfig"
                      @ready="onReady"
                      v-model="form.case"
                      v-if="addCkEditorCase"
                    ></ckeditor>
                  </div>
                  <small class="text-danger">{{ error.case }}</small>
                </b-form-group> -->

                <!-- <b-form-group id="input-group-case">
                  <label for="textarea">Instruksi: <em class="text-muted">opsional</em></label>
                  <b-form-textarea
                    id="textarea"
                    v-model="form.case"
                    placeholder=""
                    rows="3"
                    max-rows="6"
                  ></b-form-textarea>
                  <small class="text-danger">{{ error.case }}</small>
                </b-form-group> -->

                <!-- Doctor Select -->
                <!-- <b-form-group
                  v-if="currentUser.user_type_id != 1"
                  id="input-group-doctor"
                  label="Pilih Dokter:"
                  label-for="input-doctor"
                >
                  <treeselect
                    v-model="form.doctor_id"
                    :multiple="true"
                    placeholder="Pilih Dokter"
                    :options="doctors"
                  />
                  <small class="text-danger">{{ error.doctor_id }}</small>
                </b-form-group> -->

                <div class="row">
                  <div class="col-12">
                    <button
                      type="button"
                      class="btn btn-block btn-primary"
                      @click="$bvModal.show('modal-doctor-multiple')"
                    >
                      Pilih Dokter
                    </button>
                    <span class="text-danger">{{
                      doctorCollectionError.choose
                    }}</span>
                  </div>
                  <div class="col-12">
                    <table class="table thead-light">
                      <thead class="thead-light">
                        <tr>
                          <th>No</th>
                          <th>Dokter</th>
                          <th>Jam Tindakan</th>
                          <th>Aksi</th>
                        </tr>
                      </thead>
                      <tr v-for="(doctor, i) in doctorCollection" :key="i">
                        <td>{{ i + 1 }}</td>
                        <td>{{ doctor.name }}</td>
                        <td>
                          {{ doctor.start_time }} s/d {{ doctor.end_time }}
                        </td>
                        <td>
                          <b-button
                            variant="success"
                            v-b-tooltip.hover
                            title="Edit Jam Tindakan"
                            @click="editDoctorTime(i)"
                            size="sm"
                            v-if="checkAccessJamTindakan(doctor)"
                          >
                            <i class="fas fa-edit px-0"></i>
                          </b-button>
                          <b-button
                            class="ml-2"
                            variant="danger"
                            v-b-tooltip.hover
                            title="Hapus"
                            @click="deleteDoctorCollection(i)"
                            size="sm"
                            v-if="checkAccessJamTindakan(doctor)"
                          >
                            <i class="fas fa-trash px-0"></i>
                          </b-button>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>

                <!-- Input alergic -->
                <b-form-group id="input-group-alergic">
                  <label for="input-alergic">Alergi Pasien :</label>
                  <b-form-input
                    id="input-alergic"
                    v-model="patient.allergic"
                    disabled
                  ></b-form-input>
                </b-form-group>

                <!-- Input Description -->
                <b-form-group id="input-group-description">
                  <label for="input-description">Catatan Pasien:</label>
                  <b-form-textarea
                    id="input-description"
                    v-model="patient.notes"
                    rows="3"
                    disabled
                  ></b-form-textarea>
                </b-form-group>

                <!-- attachment -->
                <b-form-group id="input-group-image">
                  <label for=""
                    >Unggah Lampiran:
                    <span class="text-muted"
                      >Max 5 file dengan total ukuran akumulasi file 20mb dalam
                      1 kali unggah</span
                    ></label
                  >
                  <b-form-file
                    multiple
                    placeholder="Unggah Lampiran"
                    drop-placeholder="Drop file here..."
                    @change="upload"
                  ></b-form-file>
                  <small class="text-danger">{{ error.attachment }}</small>
                </b-form-group>

                <b-button variant="primary" type="submit">Simpan</b-button>
              </b-form>
            </div>
          </template>
        </Card>
        <!-- Edit -->
        <Card v-bind:title="'Ubah Rekam Medis'" v-if="page == 'edit'">
          <template v-slot:body>
            <div v-if="dataLoaded == true">
              <b-form
                v-if="show"
                @submit.stop.prevent="formEdit(edit.id)"
                enctype="multipart/form-data"
              >
                <!-- <b-form-group
                  id="input-group-date"
                  label="Tanggal:"
                  label-for="input-date"
                >
                  <b-form-datepicker
                    id="input-date"
                    v-model="edit.date"
                  ></b-form-datepicker>
                  <small class="text-danger">{{ error.date }}</small>
                </b-form-group> -->

                <b-form-group
                  id="input-group-date"
                  label="Tanggal:"
                  label-for="input-date"
                >
                  <b-input-group>
                    <b-form-datepicker
                      locale="id"
                      id="input-date"
                      v-model="date"
                    ></b-form-datepicker>

                    <!-- <b-form-timepicker
                      v-model="time"
                      locale="id"
                      placeholder="Waktu"
                      now-button
                      label-now-button="Waktu Sekarang"
                      reset-button
                      minutes-step="5"
                    ></b-form-timepicker> -->
                  </b-input-group>
                  <small class="text-danger">{{ error.date }}</small>
                </b-form-group>

                <div class="row">
                  <div class="col-12">
                    <b-form-group
                      id="input-group-trigger-modal-patient"
                      label="Pilih Pasien :"
                      label-for="input-trigger-modal-patient"
                    >
                      <b-form-input
                        id="input-trigger-modal-patient"
                        v-model="display.patient_name"
                        placeholder="Pilih Pasien"
                        readonly
                        @click="$bvModal.show('modal-patient')"
                      >
                      </b-form-input>
                      <small class="text-danger">{{ error.patient_id }}</small>
                    </b-form-group>
                  </div>
                  <!-- <div class="col-12 col-md-6">
                    <b-form-group
                      id="input-group-classification"
                      label="Klasifikasi:"
                      label-for="input-classification"
                    >
                      <b-form-select
                        id="input-classification"
                        v-model="edit.classification_id"
                        :options="classifications"
                      ></b-form-select>
                      <small class="text-danger">{{
                        error.classification_id
                      }}</small>
                    </b-form-group>
                  </div> -->
                </div>

                <div class="row">
                  <!-- <div class="col-12 py-0">
                    <b-form-group
                      id="input-group-classification"
                      label="Klasifikasi:"
                      label-for="input-classification"
                    >
                      <b-form-select
                        id="input-classification"
                        v-model="form.classification_id"
                        :options="classifications"
                        placeholder="Klasifikasi"
                      ></b-form-select>
                      <small class="text-danger">{{
                        error.classification_id
                      }}</small>
                    </b-form-group>
                  </div> -->
                  <div class="col-12">
                    <button
                      type="button"
                      class="btn btn-primary btn-block"
                      @click="$bvModal.show('modal-classification-patient')"
                    >
                      Pilih Klasifikasi
                    </button>
                  </div>
                </div>

                <div class="row px-4 mb-3">
                  <div class="col-12 border border-secondary rounded">
                    <span class="font-weight-bolder">Riwayat Klasifikasi:</span>
                    <b-table
                      :items="classification_patients"
                      :fields="field_classification_patients"
                      responsive="sm"
                    >
                      <template #table-colgroup="scope">
                        <col
                          v-for="field in scope.fields"
                          :key="field.key"
                          :style="{
                            width: field.key === 'actions' ? '20%' : '',
                            width: field.key === 'title' ? '30%' : '',
                          }"
                        />
                      </template>

                      <template #cell(action)="data">
                        <b-button
                          size="sm"
                          class="mr-1 btn-danger"
                          @click="deleteClassificationPatient(data.item.id)"
                          v-b-tooltip.hover
                          title="Hapus"
                          placement="bottom"
                        >
                          <i class="fas fa-trash px-0"></i>
                        </b-button>
                      </template>
                    </b-table>
                  </div>
                </div>

                <div class="row px-4">
                  <div
                    class="col-md-12 mb-2 d-flex justify-content-between bg-secondary rounded"
                    v-if="editCollapse == true"
                    @click="addCollapse = false"
                  >
                    <span class="font-weight-bolder"
                      >Riwayat Rekam Medis Sebelumnya :</span
                    >
                    <span class="font-weight-bolder"
                      ><i class="fas fa-chevron-up"></i
                    ></span>
                  </div>

                  <div
                    class="col-md-12 mb-2 d-flex justify-content-between bg-secondary rounded"
                    v-if="editCollapse == false"
                    @click="addCollapse = true"
                  >
                    <span class="font-weight-bolder"
                      >Riwayat Rekam Medis Sebelumnya :</span
                    >
                    <span class="font-weight-bolder"
                      ><i class="fas fa-chevron-down"></i
                    ></span>
                  </div>

                  <div
                    class="col-md-12 mb-4 bg-secondary rounded"
                    v-if="addCollapse == true"
                  >
                    <b-table
                      :items="itemsRecently"
                      :fields="fieldsRecently"
                      responsive="sm"
                    >
                      <template #table-colgroup="scope">
                        <col
                          v-for="field in scope.fields"
                          :key="field.key"
                          :style="{
                            width: field.key === 'actions' ? '20%' : '',
                            width: field.key === 'title' ? '30%' : '',
                          }"
                        />
                      </template>

                      <template #cell(title)="data">
                        <span v-html="data.item.list_title"></span>
                      </template>

                      <template #cell(display_date)="data">
                        <span v-if="data.item.time == '00:00'">{{
                          new Date(data.item.date).toLocaleString("id-ID", {
                            weekday: "long",
                            day: "numeric",
                            month: "numeric",
                            year: "numeric",
                          })
                        }}</span>
                        <span v-if="data.item.time != '00:00'">{{
                          new Date(data.item.date).toLocaleString("id-ID", {
                            weekday: "long",
                            day: "numeric",
                            month: "numeric",
                            year: "numeric",
                            hour: "2-digit",
                            minute: "2-digit",
                          })
                        }}</span>
                      </template>
                    </b-table>
                  </div>

                  <div class="col-md-12 d-flex justify-content-between">
                    <span class="font-weight-bolder">Diagnosa Pasien :</span>
                    <b-button
                      size="sm"
                      variant="primary"
                      class="mr-1"
                      @click="$bvModal.show('modal-patient-x-diagnose')"
                    >
                      <i class="fas fa-plus px-0"></i> Diagnosa
                    </b-button>
                  </div>

                  <div class="col-md-12 mb-4 bg-secondary rounded">
                    <b-table
                      :items="patientXDiagnose"
                      :fields="fieldsPatientXDiagnose"
                      responsive="sm"
                    >
                      <template #table-colgroup="scope">
                        <col
                          v-for="field in scope.fields"
                          :key="field.key"
                          :style="{
                            width: field.key === 'actions' ? '20%' : '',
                            width: field.key === 'title' ? '30%' : '',
                          }"
                        />
                      </template>

                      <template #cell(action)="data">
                        <b-button
                          size="sm"
                          class="mr-1 btn-danger"
                          @click="deletePatientXDiagnosa(data.item.id)"
                          v-b-tooltip.hover
                          title="Hapus"
                          placement="bottom"
                        >
                          <i class="fas fa-trash px-0"></i>
                        </b-button>
                      </template>
                    </b-table>
                  </div>
                </div>

                <b-form-group id="input-group-title">
                  <label for="textarea">Uraian:</label>
                  <b-button
                    block
                    variant="primary"
                    @click="$bvModal.show('modalUraian')"
                    >Lihat Uraian Sebelumnya</b-button
                  >
                  <div class="document-editor">
                    <ckeditor
                      id="input-title"
                      :editor="editor"
                      :config="editorConfig"
                      @ready="onReady"
                      v-model="edit.title"
                    ></ckeditor>
                  </div>
                  <small class="text-danger">{{ error.title }}</small>
                </b-form-group>
                <!-- <b-form-group id="input-group-title">
                  <label for="textarea">Uraian:</label>
                  <b-form-textarea
                    id="input-title"
                    v-model="edit.title"
                    placeholder=""
                    rows="3"
                    max-rows="6"
                    required
                  ></b-form-textarea>
                  <small class="text-danger">{{ error.title }}</small>
                </b-form-group> -->

                <!-- <b-form-group id="input-group-case">
                  <label for="textarea"
                    >Instruksi/Tindakan:
                    <em class="text-muted">opsional</em></label
                  >
                  <div class="document-editor">
                    <ckeditor
                      id="input-title"
                      :editor="editor"
                      :config="editorConfig"
                      @ready="onReady"
                      v-model="edit.case"
                    ></ckeditor>
                  </div>
                  <small class="text-danger">{{ error.case }}</small>
                </b-form-group> -->
                <!-- <b-form-group id="input-group-case">
                  <label for="textarea">Instruksi: <em class="text-muted">opsional</em></label>
                  <b-form-textarea
                    id="textarea"
                    v-model="edit.case"
                    placeholder=""
                    rows="3"
                    max-rows="6"
                  ></b-form-textarea>
                  <small class="text-danger">{{ error.case }}</small>
                </b-form-group> -->

                <!-- Doctor Select -->
                <!-- <b-form-group
                  id="input-group-doctor"
                  label="Pilih Dokter:"
                  label-for="input-doctor"
                >
                  <treeselect
                    v-model="edit.doctor_id"
                    :multiple="true"
                    placeholder="Pilih Dokter"
                    :options="doctors"
                  />
                  <small class="text-danger">{{ error.doctor_id }}</small>
                </b-form-group> -->
                <div class="row">
                  <div class="col-12">
                    <button
                      type="button"
                      class="btn btn-block btn-primary"
                      @click="$bvModal.show('modal-doctor-multiple')"
                    >
                      Pilih Dokter
                    </button>
                    <span class="text-danger">{{
                      doctorCollectionError.choose
                    }}</span>
                  </div>
                  <div class="col-12">
                    <table class="table thead-light">
                      <thead class="thead-light">
                        <tr>
                          <th>No</th>
                          <th>Dokter</th>
                          <th>Jam Tindakan</th>
                          <th>Aksi</th>
                        </tr>
                      </thead>
                      <tr v-for="(doctor, i) in doctorCollection" :key="i">
                        <td>{{ i + 1 }}</td>
                        <td>{{ doctor.name }}</td>
                        <td>
                          {{ doctor.start_time }} s/d {{ doctor.end_time }}
                        </td>
                        <td>
                          <b-button
                            variant="success"
                            v-b-tooltip.hover
                            title="Edit Jam Tindakan"
                            @click="editDoctorTime(i)"
                            size="sm"
                            v-if="checkAccessJamTindakan(doctor)"
                          >
                            <i class="fas fa-edit px-0"></i>
                          </b-button>
                          <b-button
                            class="ml-2"
                            variant="danger"
                            v-b-tooltip.hover
                            title="Hapus"
                            @click="deleteDoctorCollection(i)"
                            size="sm"
                            v-if="checkAccessJamTindakan(doctor)"
                          >
                            <i class="fas fa-trash px-0"></i>
                          </b-button>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>

                <!-- Input alergic -->
                <b-form-group id="input-group-alergic">
                  <label for="input-alergic">Alergi Pasien :</label>
                  <b-form-input
                    id="input-alergic"
                    v-model="patient.allergic"
                    disabled
                  ></b-form-input>
                </b-form-group>

                <!-- Input Description -->
                <b-form-group id="input-group-description">
                  <label for="input-description">Catatan Pasien:</label>
                  <b-form-textarea
                    id="input-description"
                    v-model="patient.notes"
                    rows="3"
                    disabled
                  ></b-form-textarea>
                </b-form-group>

                <!-- attachment -->
                <b-form-group id="input-group-image">
                  <label for=""
                    >Unggah Lampiran:
                    <span class="text-muted"
                      >Max 5 file dengan total ukuran akumulasi file 20mb dalam
                      1 kali unggah</span
                    ></label
                  >
                  <b-form-file
                    multiple
                    placeholder="Unggah Lampiran"
                    drop-placeholder="Drop file here..."
                    @change="upload"
                  ></b-form-file>
                  <small class="text-danger">{{ error.attachment }}</small>
                </b-form-group>

                <!-- <div
                  v-for="(attachment, index) in attachment"
                  :key="attachment"
                >
                  <b-card
                    class="m-2 shadow-sm"
                    v-if="attachment.length >= 1 && attachment[0] != ''"
                  >
                    <b-card-text class="d-flex justify-content-between">
                     
                      <h5>{{edit.title +" "}}{{ index + 1 }}</h5>
                      <h5
                        style="cursor: pointer"
                        @click="deleteAttach(index)"
                      >
                        X
                      </h5>
                    </b-card-text>
                  </b-card>
                </div> -->

                <div class="row">
                  <div class="col-lg-12">
                    <h6 class="d-block">Lampiran Dokumen:</h6>
                    <div
                      v-for="(attachments, index) in displayAttachment"
                      :key="index"
                      target="_blank"
                    >
                      <b-card
                        class="m-2 shadow-sm"
                        v-if="
                          displayAttachment.length >= 1 &&
                          displayAttachment[0] != ''
                        "
                      >
                        <b-card-text class="d-flex justify-content-between">
                          <!-- <h5>{{edit.title +" "}}{{ index + 1 }}</h5> -->
                          <h5>{{ edit.attachment_title[index] }}</h5>
                          <h5
                            style="cursor: pointer"
                            @click="deleteAttach(index)"
                          >
                            X
                          </h5>
                        </b-card-text>
                      </b-card>
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <h6 class="d-block">Lampiran Gambar :</h6>
                    <div class="col-lg-12">
                      <div class="row">
                        <!-- <div
                        v-for="(display, index) in displayImage"
                        :key="display"
                      > -->
                        <div
                          class="col-md-4"
                          v-for="(display, index) in displayImage"
                          :key="display"
                        >
                          <div class="d-flex justify-content-between">
                            <img
                              class="image"
                              :src="displayImage[index]"
                              style="
                                position: relative !important;
                                cursor: pointer;
                              "
                              @click="showImage(displayImage[index])"
                            />

                            <h5
                              style="cursor: pointer"
                              @click="deleteAttachImage(index)"
                            >
                              X
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <b-button variant="info" @click="page = 'drawing'" class="mx-2"
                  >Coretan Dokter</b-button
                >

                <b-button variant="primary" type="submit">Simpan</b-button>
              </b-form>
            </div>
          </template>
        </Card>

        <!-- Detail -->
        <Card v-bind:title="'Detail Rekam Medis'" v-if="page == 'detail'">
          <template v-slot:body>
            <div class="container" v-if="dataLoaded == true">
              <div class="d-flex justify-content-end">
                <!-- <b-button
                  variant="primary"
                  @click="page = 'drawing'"
                >Gambar</b-button> -->
              </div>
              <table class="table mb-3">
                <tr>
                  <td><strong>Tanggal</strong></td>
                  <td v-if="detail.time == '00:00'">
                    {{
                      new Date(detail.date).toLocaleString("id-ID", {
                        weekday: "long",
                        day: "numeric",
                        month: "numeric",
                        year: "numeric",
                      })
                    }}
                  </td>
                  <td v-if="detail.time != '00:00'">
                    {{
                      new Date(detail.date).toLocaleString("id-ID", {
                        weekday: "long",
                        day: "numeric",
                        month: "numeric",
                        year: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                      })
                    }}
                  </td>
                </tr>
                <tr>
                  <td><strong>Nama Pasien</strong></td>
                  <td>{{ detail.patient_name }}</td>
                </tr>
                <!-- <tr>
                  <td><strong>Klasifikasi</strong></td>
                  <td>{{ detail.classification_name }}</td>
                </tr> -->
                <tr>
                  <td><strong>Klasifikasi</strong></td>
                  <td>
                    <ul v-for="i in detail_classification_patients" :key="i">
                      <li>{{ i.classification_name }}</li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td><strong>Diagnosa Pasien</strong></td>
                  <td>
                    <ul v-for="i in detailPatientXDiagnose" :key="i">
                      <li>{{ i.diagnose_name }}</li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td><strong>Telepon</strong></td>
                  <td>{{ detail.patient_mobile_phone }}</td>
                </tr>
                <tr>
                  <td><strong>Alamat</strong></td>
                  <td>{{ detail.patient_address }}</td>
                </tr>
                <tr>
                  <td><strong>Alergi</strong></td>
                  <td>{{ detail.patient_allergy }}</td>
                </tr>
                <tr>
                  <td><strong>Catatan</strong></td>
                  <td>{{ detail.patient_notes }}</td>
                </tr>
                <tr>
                  <td><strong>Uraian </strong></td>
                  <td v-html="detail.title"></td>
                </tr>
                <!-- <tr>
                  <td><strong>Instruksi/Tindakan</strong></td>
                  <td v-html="detail.case"></td>
                </tr> -->
                <tr>
                  <td><strong>Dokter</strong></td>
                  <td>
                    <ul>
                      <li
                        v-for="(doctor_name, index) in detail.doctor_name"
                        :key="index"
                      >
                        {{ doctor_name }} ({{
                          detail.doctor_start_time[index]
                        }}
                        - {{ detail.doctor_end_time[index] }})
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <td><strong>Obat</strong></td>
                  <td>
                    <ul>
                      <li
                        v-for="(
                          ma_medicines, index
                        ) in detail.medical_action_medicines"
                        :key="index"
                      >
                        {{ ma_medicines.name }}
                      </li>
                    </ul>
                  </td>
                </tr>
              </table>

              <div class="row">
                <div class="col-lg-12">
                  <h6 class="d-block">Lampiran Dokumen :</h6>
                  <div
                    v-for="(attachments, index) in displayAttachment"
                    :key="index"
                    target="_blank"
                  >
                    <a :href="displayAttachment[index]" download="file">
                      <b-card
                        class="m-2 shadow-sm"
                        v-if="
                          displayAttachment.length >= 1 &&
                          displayAttachment[0] != ''
                        "
                      >
                        <b-card-text class="d-flex justify-content-start">
                          <i class="fas fa-file mr-2 d-inline"></i>
                          <h5>
                            {{ detail.attachment_title[index] }}
                          </h5>
                        </b-card-text>
                      </b-card>
                    </a>
                  </div>
                </div>
                <div class="col-lg-12">
                  <h6 class="d-block">Lampiran Gambar :</h6>
                  <div class="col-lg-12">
                    <div class="row">
                      <!-- <div
                        v-for="(display, index) in displayImage"
                        :key="display"
                      > -->
                      <div
                        class="col-md-4"
                        v-for="(display, index) in displayImage"
                        :key="display"
                      >
                        <!-- <a :href="displayImage[index]" target="_blank">
                          <img
                            class="image"
                            :src="displayImage[index]"
                            style="position: relative !important"
                          />
                        </a> -->
                        <img
                          class="image"
                          :src="displayImage[index]"
                          style="position: relative !important; cursor: pointer"
                          @click="showImage(displayImage[index])"
                        />
                      </div>
                      <!-- </div> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </Card>
      </div>
      <div class="col-md-12" v-if="page != 'drawing'">
        <Card>
          <template v-slot:body>
            <!-- Filter -->
            <div class="row justify-content-between mt-n3">
              <div class="col-md-6">
                <button
                  class="btn mx-1 btn-timeseries"
                  @click="btnActiveOnClick('days')"
                  v-bind:class="{
                    'btn-info': btnActive.days,
                    'btn-outline-info': btnActive.days == false,
                  }"
                >
                  Hari Ini
                </button>
                <button
                  class="btn mx-1 btn-timeseries"
                  @click="btnActiveOnClick('month')"
                  v-bind:class="{
                    'btn-info': btnActive.month,
                    'btn-outline-info': btnActive.month == false,
                  }"
                >
                  Bulan Ini
                </button>
                <button
                  class="btn mx-1 btn-timeseries"
                  @click="btnActiveOnClick('lastMonth')"
                  v-bind:class="{
                    'btn-info': btnActive.lastMonth,
                    'btn-outline-info': btnActive.lastMonth == false,
                  }"
                >
                  Bulan Kemarin
                </button>
                <button
                  class="btn mx-1 btn-timeseries"
                  @click="btnActiveOnClick('year')"
                  v-bind:class="{
                    'btn-info': btnActive.year,
                    'btn-outline-info': btnActive.year == false,
                  }"
                >
                  Tahun Ini
                </button>
                <button
                  class="btn mx-1 btn-timeseries"
                  v-bind:class="{
                    'btn-info': btnActive.period,
                    'btn-outline-info': btnActive.period == false,
                  }"
                  @click="btnPeriodeOnClick"
                >
                  Periode
                </button>
              </div>

              <div class="col-md-3 col-12">
                <b-input-group>
                  <!-- <template #prepend>
                    <b-input-group-text squared>
                      <b-icon-search></b-icon-search>
                    </b-input-group-text>
                  </template> -->
                  <b-form-input
                    type="text"
                    v-model="filter.name"
                    placeholder="Cari Nama Pasien"
                    @keyup="patientOnKeyUp"
                  ></b-form-input>
                </b-input-group>
              </div>
              <div class="col-md-3 col-12">
                <b-input-group>
                  <!-- <template #prepend>
                    <b-input-group-text squared>
                      <b-icon-search></b-icon-search>
                    </b-input-group-text>
                  </template> -->
                  <treeselect
                    v-model="filter.diagnose_id"
                    :multiple="true"
                    placeholder="Cari Diagnosa"
                    :options="diagnoseItems"
                    @select="diagnoseOnSelect"
                    @deselect="diagnoseOnDeselect"
                    :clearable="false"
                  />
                  <!-- <b-form-input
                    type="text"
                    v-model="filter.diagnose"
                    placeholder="Cari Diagnosa"
                    @keyup="patientOnKeyUp"
                  ></b-form-input> -->
                </b-input-group>
              </div>
            </div>

            <!-- timeseries filter -->
            <div class="row">
              <div class="col-md-9" v-if="onShowPeriod">
                <span class="d-none d-sm-block">
                  <b-input-group>
                    <b-form-input
                      v-model="filter.start_date"
                      type="text"
                      placeholder="Awal"
                      autocomplete="off"
                      readonly
                    ></b-form-input>
                    <b-input-group-append>
                      <b-form-datepicker
                        v-model="filter.start_date"
                        button-only
                        right
                        reset-button
                        locale="id"
                        aria-controls="example-input"
                      ></b-form-datepicker>
                    </b-input-group-append>
                    <b-button squared variant="light">s/d</b-button>
                    <b-form-input
                      v-model="filter.end_date"
                      type="text"
                      placeholder="Akhir"
                      autocomplete="off"
                      readonly
                    ></b-form-input>
                    <b-input-group-append>
                      <b-form-datepicker
                        v-model="filter.end_date"
                        button-only
                        right
                        reset-button
                        locale="id"
                        aria-controls="example-input"
                      ></b-form-datepicker>
                    </b-input-group-append>
                    <template #append>
                      <b-button
                        squared
                        @click="btnSearchOnClick"
                        variant="success"
                        >Cari</b-button
                      >
                      <b-button squared @click="resetFilter" variant="danger"
                        >Reset</b-button
                      >
                    </template>
                  </b-input-group>
                </span>
                <span class="d-block d-sm-none">
                  <div class="row">
                    <div class="col-12 pb-1">
                      <b-input-group>
                        <b-form-input
                          v-model="filter.start_date"
                          type="text"
                          placeholder="Awal"
                          autocomplete="off"
                          readonly
                        ></b-form-input>
                        <b-input-group-append>
                          <b-form-datepicker
                            v-model="filter.start_date"
                            button-only
                            right
                            reset-button
                            locale="id"
                            aria-controls="example-input"
                          ></b-form-datepicker>
                        </b-input-group-append>
                        <template #append>
                          <b-button
                            squared
                            @click="btnSearchOnClick"
                            variant="success"
                            >Cari</b-button
                          >
                          <b-button
                            squared
                            @click="resetFilter"
                            variant="danger"
                            >Reset</b-button
                          >
                        </template>
                      </b-input-group>
                    </div>
                    <div class="col-12 pt-0">
                      <b-input-group>
                        <b-button squared variant="light">s/d</b-button>
                        <b-form-input
                          v-model="filter.end_date"
                          type="text"
                          placeholder="Akhir"
                          autocomplete="off"
                          readonly
                        ></b-form-input>
                        <b-input-group-append>
                          <b-form-datepicker
                            v-model="filter.end_date"
                            button-only
                            right
                            reset-button
                            locale="id"
                            aria-controls="example-input"
                          ></b-form-datepicker>
                        </b-input-group-append>
                      </b-input-group>
                    </div>
                  </div>
                </span>
              </div>
            </div>
          </template>
        </Card>

        <div class="row">
          <div class="col-md-4">
            <Card v-bind:title="'Jumlah Pasien Per-Diagnosa'">
              <template v-slot:body>
                <TablePatientXDiagnose></TablePatientXDiagnose>
              </template>
            </Card>
          </div>
          <div class="col-md-8">
            <Card v-bind:title="'Semua Rekam Medis'">
              <template v-slot:body>
                <div class="row">
                  <div class="col-md-4">
                    <span
                      style="width: 150px; color: #a12260 !important"
                      class="font-weight-bold"
                      >{{ recordData }} Baris Data</span
                    >
                  </div>
                </div>

                <!-- table -->
                <div v-if="dataLoaded == true">
                  <b-table
                    striped
                    hover
                    :items="items"
                    :fields="fields"
                    responsive="sm"
                  >
                    <template #table-colgroup="scope">
                      <col
                        v-for="field in scope.fields"
                        :key="field.key"
                        :style="{
                          width: field.key === 'actions' ? '20%' : '',
                          width: field.key === 'title' ? '30%' : '',
                        }"
                      />
                    </template>

                    <template #cell(title)="data">
                      <span v-html="data.item.list_title"></span>
                    </template>

                    <template #cell(display_date)="data">
                      <span v-if="data.item.time == '00:00'">{{
                        new Date(data.item.date).toLocaleString("id-ID", {
                          weekday: "long",
                          day: "numeric",
                          month: "numeric",
                          year: "numeric",
                        })
                      }}</span>
                      <span v-if="data.item.time != '00:00'">{{
                        new Date(data.item.date).toLocaleString("id-ID", {
                          weekday: "long",
                          day: "numeric",
                          month: "numeric",
                          year: "numeric",
                          hour: "2-digit",
                          minute: "2-digit",
                        })
                      }}</span>
                    </template>
                    <template #cell(actions)="data">
                      <b-button
                        size="sm"
                        class="mr-1 btn-info"
                        @click="handleDetail(data.item.id)"
                        v-b-tooltip.hover
                        title="Detail"
                        placement="bottom"
                      >
                        <i class="fas fa-eye px-0"></i>
                      </b-button>
                      <b-button
                        size="sm"
                        class="mr-1 btn-success"
                        @click="handleEdit(data.item.id)"
                        v-b-tooltip.hover
                        title="Ubah"
                        placement="bottom"
                        v-if="manipulateBtn == true"
                      >
                        <i class="fas fa-edit px-0"></i>
                      </b-button>
                      <b-button
                        size="sm"
                        class="mr-1 btn-danger"
                        @click="deleteData(data.item.id)"
                        v-b-tooltip.hover
                        title="Hapus"
                        placement="bottom"
                        v-if="
                          manipulateBtn == true && currentUser.user_type_id != 1
                        "
                      >
                        <i class="fas fa-trash px-0"></i>
                      </b-button>

                      <b-button
                        @click="handleDrawList(data.item.id)"
                        class="btn bg-primary text-white"
                        >Coretan Dokter</b-button
                      >
                    </template>
                  </b-table>
                  <!-- pagination -->
                  <b-pagination
                    v-if="items.length != 0"
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    @page-click="pageOnClick"
                    class="mt-4"
                  ></b-pagination>
                </div>
              </template>
            </Card>
          </div>
        </div>
      </div>
      <div class="col-md-12" v-if="page == 'drawing'">
        <Card v-bind:title="'Gambar Dokter'">
          <template v-slot:body>
            <div v-if="dataLoaded == true">
              <div class="container">
                <div class="row">
                  <div class="col-12 mt-2">
                    <VueSignaturePad
                      id="signature"
                      width="100%"
                      height="500px"
                      ref="signaturePad"
                      :options="options"
                    />
                    <span class="text-danger mt-2"
                      >*Setelah Menekan Tombol Simpan, Coretan Akan Langsung
                      Tersimpan</span
                    >
                  </div>
                </div>
                <div class="row justify-content-end">
                  <div class="col-3 mt-2">
                    <button
                      class="btn btn-danger mx-1"
                      @click="handleDetail(detail.id)"
                    >
                      Batal
                    </button>
                    <button class="btn btn-secondary mx-1" @click="undo">
                      Undo
                    </button>
                    <button class="btn btn-primary mx-1" @click="save">
                      Simpan
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </Card>
      </div>
    </div>
    <Modal
      :selectedDoctor="form.doctor_id.toString()"
      @chosenPatient="setPatient"
      @submitedPatient="setPatient"
    />
    <ModalClassificationPatient
      :patient_id="page == 'add' ? form.patient_id : edit.patient_id"
      :page="page"
      @updateFormClassificationChoosenList="getClassificationPatient"
    />

    <ModalPatientXDiagnose
      :patient_id="page == 'add' ? form.patient_id : edit.patient_id"
      :page="page"
      @updateFormDiagnoseChoosenList="getPatientXDiagnose"
    />
    <!-- <ModalDrawing
      :detail="detail"
      :attachment="attachment"
    /> -->

    <ModalSatuSehat />
    <ModalDoctorMultiple
      @checkData="chooseDoctorMultiple"
      @unCheckData="uncheckDoctorMultiple"
      :dataCollection="doctorCollection"
      :filterModalDoctorMultiple="filterModalDoctorMultiple"
    />

    <!-- Modal Doctor TIme -->
    <b-modal
      id="modal-doctor-time"
      @ok="addDoctorMultiple"
      @cancel="cancelAddDoctorMultiple"
    >
      <template #modal-title>Form Jam Tindakan</template>
      <b-form-group id="input-group-doctor-start_time">
        <label for="input-doctor-start_time"
          >Jam Mulai : <span class="text-muted"> Contoh 08:00</span></label
        >
        <!-- <b-form-timepicker
          v-model="doctorCollectionForm.start_time"
          locale="id"
          placeholder="Jam Mulai"
          now-button
          label-now-button="Waktu Sekarang"
          reset-button
          minutes-step="5"
        ></b-form-timepicker> -->
        <b-form-input
          id="input-doctor-start-time"
          v-model="doctorCollectionForm.start_time"
          placeholder="Jam Mulai"
          @input="handleDoctorStartTime"
        ></b-form-input>
        <small class="text-danger">{{
          doctorCollectionError.start_time
        }}</small>
      </b-form-group>
      <b-form-group id="input-group-doctor-end_time">
        <label for="input-doctor-start_time"
          >Jam Selesai : <span class="text-muted"> Contoh 13:30</span></label
        >
        <!-- <b-form-timepicker
          v-model="doctorCollectionForm.end_time"
          locale="id"
          placeholder="Jam Selesai"
          now-button
          label-now-button="Waktu Sekarang"
          reset-button
          minutes-step="5"
        ></b-form-timepicker> -->
        <b-form-input
          id="input-doctor-end-time"
          v-model="doctorCollectionForm.end_time"
          placeholder="Jam Selesai"
          @input="handleDoctorEndTime"
        ></b-form-input>
        <small class="text-danger">{{ doctorCollectionError.end_time }}</small>
      </b-form-group>
    </b-modal>

    <b-modal id="modalShowImage" size="xl" hide-footer>
      <div class="image-container">
        <img :src="selectedImage" alt="" />
      </div>
    </b-modal>

    <b-modal id="modalUraian" size="xl" hide-footer>
      <b-card
        no-body
        class="mb-1"
        v-for="(titleHistory, i) in edit.medical_record_histories"
        :key="i"
      >
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button block v-b-toggle="`uraian${i}`" variant="primary"
            >Uraian
            {{
              new Date(titleHistory.date).toLocaleString("id-ID", {
                weekday: "long",
                day: "numeric",
                month: "numeric",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
              })
            }}
            - {{ titleHistory.creator.name }}</b-button
          >
        </b-card-header>
        <b-collapse
          :id="`uraian${i}`"
          :accordion="`my-accordion-${i}`"
          role="tabpanel"
        >
          <b-card-body>
            <div v-html="titleHistory.title"></div>
          </b-card-body>
        </b-collapse>
      </b-card>
    </b-modal>
  </div>
</template>

<style scoped>
.image-container {
  width: 100%;
  height: 100%;
}

.image-container img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}
</style>

<script>
import Modal from "@/component/general/ModalPatientMedicalRecord.vue";
import ModalDoctorMultiple from "@/component/general/ModalDoctorMultiple.vue";
import ModalPatientXDiagnose from "@/component/masters/diagnoses/Modal.vue";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import Card from "@/view/content/Card.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { debounce } from "@/core/modules/Helper.js";
import module from "@/core/modules/CrudModule.js";
import validation from "@/core/modules/ValidationModule.js";
import { getUser } from "@/core/services/jwt.service.js";
import DecoupledEditor from "@ckeditor/ckeditor5-build-decoupled-document";
import moment from "moment";
import TablePatientXDiagnose from "@/component/patients/TablePatientXDiagnose.vue";
import ModalSatuSehat from "@/component/general/ModalSatuSehat.vue";
import ModalClassificationPatient from "@/component/masters/classifications/Modal.vue";

// import ModalDrawing from '@/component/general/ModalDrawing.vue';
export default {
  components: {
    Card,
    Treeselect,
    Modal,
    ModalPatientXDiagnose,
    TablePatientXDiagnose,
    // ModalDrawing,
    ModalSatuSehat,
    ModalDoctorMultiple,
    ModalClassificationPatient,
  },
  data() {
    return {
      classifications: [],
      selectedImage: "",
      //
      doctorCollection: [],
      doctorCollectionForm: {
        id: "",
        name: "",
        start_time: "",
        end_time: "",
      },
      doctorCollectionError: {
        id: "",
        name: "",
        start_time: "",
        end_time: "",
        choose: "",
      },
      doctorTimePurpose: "add",
      doctorCollectionIndex: 0,
      filterModalDoctorMultiple: {
        ids: "",
      },
      addCkEditor: false,
      addCkEditorCase: false,
      // data
      items: null,
      recordData: 0,
      formData: new FormData(),
      page: "add",
      // patientData: [],
      attachment: "",
      displayImage: [],
      displayAttachment: [],
      display: {
        patient_name: this.$route.query.patient_name ?? "",
      },
      doctors: [],

      // date
      date: "",
      time: "",

      // collapse
      addCollapse: false,
      editCollapse: false,

      // diagnose
      diagnoseItems: [],

      // other
      dataLoaded: false,
      itemsRecently: [],
      fieldsRecently: [
        {
          key: "display_date",
          label: "Tanggal",
          sortable: true,
        },
        {
          key: "patient_name_relation",
          label: "Pasien",
          sortable: true,
        },
        {
          key: "title",
          label: "Uraian ",
          sortable: true,
        },
      ],
      detailPatientXDiagnose: [],
      patientXDiagnose: [],
      fieldsPatientXDiagnose: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "diagnose_code",
          label: "Kode",
          sortable: true,
        },
        {
          key: "diagnose_name",
          label: "Diagnosa",
          sortable: true,
        },
        {
          key: "action",
          label: "Aksi",
        },
      ],
      fields: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "display_date",
          label: "Tanggal",
          sortable: true,
        },
        {
          key: "patient_name_relation",
          label: "Pasien",
          sortable: true,
        },
        // {
        //   key: "title",
        //   label: "Uraian ",
        //   sortable: true,
        // },

        { key: "actions", label: "Aksi" },
      ],

      error: {
        title: "",
        case: "",
        // date: "",
        patient_id: "",
        attachment: "",
        doctor_id: "",
      },

      form: {
        title: "",
        case: "",
        // date: "",
        appointment_id: this.$route.query.appointment_id ?? "",
        patient_id: this.$route.query.patient_id ?? "",
        // doctor_id: this.$route.query.doctor_id
        //   ? [this.$route.query.doctor_id]
        //   : [],
        doctor_start_time: [],
        doctor_end_time: [],
        doctor_id: [],
      },
      edit: [],
      detail: [],
      // data
      show: true,

      // draw
      options: {
        penColor: "#000000",
      },
      // access management
      manipulateBtn: false,
      // filter
      filter: {
        name: "",
        diagnose_id: [],
        start_date: "",
        end_date: "",
      },
      // Pagination
      perPage: 20,
      currentPage: 1,
      totalRows: 1,
      // filter timeseries
      onShowPeriod: false,
      btnActive: {
        days: true,
        month: false,
        lastMonth: false,
        year: false,
        period: false,
      },
      lastPeriodType: "days",
      // patient info data
      patient: {
        allergic: this.$route.query.allergy,
        notes: this.$route.query.notes,
      },
      // other
      doctorDisabled: true,
      currentUser: getUser(),
      // ckeditor
      editor: DecoupledEditor,
      editorConfig: {
        toolbar: [
          "heading",
          "|",
          "fontFamily",
          "fontSize",
          "fontColor",
          "fontBackgroundColor",
          "|",
          "bold",
          "italic",
          "underline",
          "strikethrough",
          "|",
          "indent",
          "outdent",
          "|",
          "alignment",
          "|",
          "link",
          "blockQuote",
          "bulletedList",
          "numberedList",
          "|",
          "insertTable",
          "|",
          "mediaEmbed",
          "|",
          "undo",
          "redo",
        ],
        alignment: {
          options: ["left", "right", "center", "justify"],
        },
        table: {
          toolbar: ["tableColumn", "tableRow", "mergeTableCells"],
        },
      },
      // Classification Patients
      classification_patients: [],
      detail_classification_patients: [],
      field_classification_patients: [
        {
          key: "number",
          label: "#",
          sortable: true,
        },
        {
          key: "classification_name",
          label: "Klasifikasi",
          sortable: true,
        },
        {
          key: "action",
          label: "Aksi",
        },
      ],
    };
  },
  methods: {
    async getClassificationPatient(patient_id) {
      let route = "classification-patients/all";
      let response = await module.paginate(route, `?patient_id=${patient_id}`);
      if (this.page != "detail") {
        this.classification_patients = response.data;
      } else {
        console.log(
          "detail_classification_patients =====================================",
          this.detail_classification_patients
        );
        this.detail_classification_patients = response.data;
      }
    },
    async deleteClassificationPatient(id) {
      let result = await module.delete("classification-patients/" + id);
      if (result) {
        this.getClassificationPatient(this.form.patient_id);

        this.$root.$emit(
          "updateModalClassificationPatientFromPage",
          this.form.patient_id
        );
      }
    },
    async getClassification() {
      let response = await module.setSelectOption("classifications");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.classifications = response.data;
        this.classifications.unshift({
          text: "Pilih Klasifikasi",
          value: null,
          disabled: true,
        });
      }
    },
    showImage(url) {
      this.selectedImage = url;
      this.$bvModal.show("modalShowImage");
    },
    handleDoctorStartTime(event) {
      if (
        this.doctorCollectionForm.start_time.length >= 2 &&
        event.charAt(2) !== ":"
      ) {
        let firstTwoChars = event.slice(0, 2);
        let remainingChars = event.slice(2);
        // Gabungkan dengan tanda ":" di antara mereka
        this.doctorCollectionForm.start_time = `${firstTwoChars}:${remainingChars}`;
      }
    },
    handleDoctorEndTime(event) {
      if (
        this.doctorCollectionForm.end_time.length >= 2 &&
        event.charAt(2) !== ":"
      ) {
        let firstTwoChars = event.slice(0, 2);
        let remainingChars = event.slice(2);
        // Gabungkan dengan tanda ":" di antara mereka
        this.doctorCollectionForm.end_time = `${firstTwoChars}:${remainingChars}`;
      }
    },
    checkAccessJamTindakan(doctor) {
      if (this.currentUser.user_type_id == 1) {
        if (doctor.id == this.currentUser.id) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    chooseDoctorMultiple(data) {
      this.doctorCollectionForm.name = data.name;
      this.doctorCollectionForm.id = data.id;
      this.doctorCollectionForm.start_time = "";
      this.doctorCollectionForm.end_time = "";
      this.$bvModal.show("modal-doctor-time");
    },
    validationDoctorTime() {
      let status = true;
      if (this.doctorCollectionForm.start_time == "") {
        status = false;
        this.doctorCollectionError.start_time = "Kolom jam mulai wajib diisi";
      } else {
        this.doctorCollectionError.start_time = "";
      }
      if (this.doctorCollectionForm.end_time == "") {
        status = false;
        this.doctorCollectionError.end_time = "Kolom jam selesai wajib diisi";
      } else {
        this.doctorCollectionError.end_time = "";
      }

      if (
        this.doctorCollectionForm.end_time <
        this.doctorCollectionForm.start_time
      ) {
        status = false;
        this.doctorCollectionError.end_time = `Kolom Jam selesai harus setelah jam mulai`;
      } else {
        this.doctorCollectionError.end_time = "";
      }

      return status;
    },
    addDoctorMultiple(bvModalEvt) {
      console.log("this.doctorTimePurpose", this.doctorTimePurpose);
      if (this.validationDoctorTime() == false) {
        bvModalEvt.preventDefault();
        Swal.fire({
          title: "Gagal",
          text: "Harap periksa kembali form",
          icon: "error",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
        throw new Error("validation error");
      }
      console.log("this.form.doctor_id", this.form.doctor_id);
      if (this.doctorTimePurpose == "add") {
        let clone = { ...this.doctorCollectionForm };
        this.doctorCollection.push(clone);
        this.form.doctor_id.push(clone.id);
        this.form.doctor_start_time.push(clone.start_time);
        this.form.doctor_end_time.push(clone.end_time);
      } else {
        this.doctorCollection[this.doctorCollectionIndex].start_time =
          this.doctorCollectionForm.start_time;
        this.doctorCollection[this.doctorCollectionIndex].end_time =
          this.doctorCollectionForm.end_time;

        this.form.doctor_start_time[this.doctorCollectionIndex] =
          this.doctorCollectionForm.start_time;
        this.form.doctor_end_time[this.doctorCollectionIndex] =
          this.doctorCollectionForm.end_time;
      }

      this.$bvModal.hide("modal-doctor-time");
      this.$bvModal.hide("modal-doctor-multiple");

      console.log("doctorCollection", this.doctorCollection);
      console.log("doctor_id", this.form.doctor_id);
      console.log("doctor_start_time", this.form.doctor_start_time);
      console.log("doctor_end_time", this.form.doctor_end_time);
      this.resetDoctorCollectionForm();
    },
    cancelAddDoctorMultiple() {
      this.$root.$emit(
        "cancelAddDoctorMultipleFromAddMedicalRecord",
        this.doctorCollectionForm
      );
    },
    resetDoctorCollectionForm() {
      this.doctorCollectionForm.start_time = "";
      this.doctorCollectionForm.end_time = "";

      this.doctorTimePurpose = "add";
    },
    deleteDoctorCollection(index) {
      this.doctorCollection.splice(index, 1);
      this.form.doctor_id.splice(index, 1);
      this.form.doctor_start_time.splice(index, 1);
      this.form.doctor_end_time.splice(index, 1);
    },
    editDoctorTime(i) {
      this.doctorTimePurpose = "edit";
      let selectedData = this.doctorCollection[i];
      this.doctorCollectionForm.start_time = selectedData.start_time;
      this.doctorCollectionForm.end_time = selectedData.end_time;
      this.doctorCollectionIndex = i;

      this.$bvModal.show("modal-doctor-time");
    },
    uncheckDoctorMultiple(value) {
      for (let a = 0; a < this.doctorCollection.length; a++) {
        if (this.doctorCollection[a].id == value.id) {
          this.doctorCollection.splice(a, 1);
        }
      }
    },

    onReady(editor) {
      // Insert the toolbar before the editable area.
      editor.ui
        .getEditableElement()
        .parentElement.insertBefore(
          editor.ui.view.toolbar.element,
          editor.ui.getEditableElement()
        );
    },
    btnSearchOnClick() {
      this.list();
      console.log("click jalankan emit");
      this.$root.$emit("setFilterDataOnClick", this.filter);
    },
    async getTreeselectDiagnose() {
      let response = await module.setTreeSelect("diagnoses");
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.diagnoseItems = response.data;
      }
    },

    handleAdd() {
      this.page = "add";
      this.patientXDiagnose = [];
      // this.getPatientsOption();
      this.attachment = [];
      this.doctorCollection = [];
      this.date = "";
      this.time = "";
      this.display.patient_name = null;
    },
    handleList() {
      this.list();
    },
    async handleEdit(id) {
      this.edit = [];
      this.patientXDiagnose = [];
      this.dataLoad = false;
      this.page = "edit";
      this.attachment = [];
      this.doctorCollection = [];

      // get medical record patient data
      await this.getMedicalRecord(id);

      // set patient id for modal
      this.form.patient_id = this.edit.patient_id;

      // update form choosen diagnose list
      this.getPatientXDiagnose(this.edit.patient_id);
      this.getClassificationPatient(this.edit.patient_id);

      // trigger update data modal
      this.$root.$emit(
        "updateModalPatientXDiagnoseFromPage",
        this.form.patient_id
      );
      this.$root.$emit(
        "updateModalClassificationPatientFromPage",
        this.form.patient_id
      );
    },
    handleDrawList(id) {
      this.attachment = [];
      this.getMedicalRecord(id);
      this.handleDraw();
    },
    handleDraw() {
      this.page = "drawing";
    },
    async handleDetail(id) {
      this.detail = [];
      this.attachment = [];
      this.dataLoad = false;
      this.page = "detail";
      await this.getMedicalRecord(id);
      console.log(this.detail);
      this.getPatientXDiagnose(this.detail.patient_id);
      this.getClassificationPatient(this.detail.patient_id);
    },
    setPatient(value) {
      this.form.patient_id = value.id;
      this.display.patient_name = value.name;
      this.patient.allergic = value.allergic;
      this.patient.notes = value.notes;

      // if (this.currentUser.user_type_id != 1) {
      //   this.form.doctor_id = "";
      //   this.getDoctorsOption();
      // }
      this.doctorDisabled = false;
      this.$bvModal.hide("modal-patient");
      this.getRecenly();

      // update form choosen model
      this.getPatientXDiagnose(this.form.patient_id);
      this.getClassificationPatient(this.form.patient_id);

      // trigger update data modal
      this.$root.$emit(
        "updateModalPatientXDiagnoseOnSetPatient",
        this.form.patient_id
      );
      this.$root.$emit(
        "updateModalClassificationPatientOnSetPatient",
        this.form.patient_id
      );
      if (this.currentUser.user_type_id != 1) {
        this.filterModalDoctorMultiple.ids = value.doctor_in_charge;
      }
    },
    deleteAttach(index) {
      this.displayAttachment.splice(index, 1);
    },
    deleteAttachImage(index) {
      this.displayImage.splice(index, 1);
    },

    patientOnKeyUp() {
      debounce(() => {
        this.filter.start_date = "";
        this.filter.end_date = "";
        if (this.filter.name == "") {
          this.btnActiveOnClick(this.lastPeriodType);
        }
        this.list();
        this.$root.$emit("setFilterDataOnClick", this.filter);
      }, 500);
    },

    async getRecenly() {
      let route = `medical-record/recently`;
      let filterParams = `&patient_id=${this.form.patient_id}`;
      let response = await module.paginate(
        route,
        `?page=${this.currentPage}&page_size=3${filterParams}`
      );
      this.itemsRecently = response.data;
    },

    diagnoseOnSelect(evt) {
      console.log("CLEAR");
      let diagnose = this.diagnoseItems;
      let found = diagnose.findIndex((x) => x.id == evt.id);
      this.filter.diagnose_id.push(diagnose[found].id);
      console.log(this.filter.diagnose_id);
      this.list();
      this.$root.$emit("setFilterDataOnClick", this.filter);
      // console.log("CLEAR 2");
    },

    diagnoseOnDeselect(evt) {
      let diagnose = this.filter.diagnose_id;
      let found = diagnose.findIndex((x) => x == evt.id);
      this.filter.diagnose_id.splice(found, 1);
      this.list();
      this.$root.$emit("setFilterDataOnClick", this.filter);
    },

    async list() {
      let route;
      if (this.currentUser.user_type_id == 1) {
        route = `medical-records/get-by-doctor-in-charge/${this.currentUser.id}`;
      } else {
        route = "medical-records";
      }

      // stringify diagnose id
      let diagnose_id = JSON.stringify(this.filter.diagnose_id);

      let filterParams = `&name=${this.filter.name}&diagnose_id=${diagnose_id}&start_date=${this.filter.start_date}&end_date=${this.filter.end_date}`;
      let response = await module.paginate(
        route,
        `?page=${this.currentPage}&page_size=${this.perPage}${filterParams}`
      );
      let pagination = response.meta.pagination;
      this.totalRows = pagination.total;
      this.recordData = pagination.total;
      let a, b;
      b = this.perPage * (this.currentPage - 1) + 1;
      for (a = 0; a < response.data.length; a++) {
        response.data[a].number = b + a;
      }
      this.items = response.data;
      this.dataLoaded = true;
    },

    async getPatientXDiagnose(patient_id) {
      let route = "patient-x-diagnoses/all";
      let response = await module.paginate(route, `?patient_id=${patient_id}`);
      if (this.page != "detail") {
        this.patientXDiagnose = response.data;
        console.log(response.data, "DTATTTT", patient_id);
      } else {
        console.log("detailPatientXDiagnose", this.detailPatientXDiagnose);
        this.detailPatientXDiagnose = response.data;
      }
    },

    async deleteData(id) {
      let result = await module.delete("medical-records/" + id);
      if (result) {
        this.list();
        this.handleAdd();
      }
    },

    async deletePatientXDiagnosa(id) {
      let result = await module.delete("patient-x-diagnoses/" + id);
      console.log(result, "resss");
      if (result) {
        this.getPatientXDiagnose(this.form.patient_id);

        this.$root.$emit(
          "updateModalPatientXDiagnoseFromPage",
          this.form.patient_id
        );
      }
    },

    async getDoctorsOption() {
      let route;
      if (
        typeof this.edit.patient_id !== "undefined" &&
        this.edit.patient_id != ""
      ) {
        route = `doctors/get-by-patient/${this.edit.patient_id}`;
      } else if (
        typeof this.form.patient_id !== "undefined" &&
        this.form.patient_id != ""
      ) {
        route = `doctors/get-by-patient/${this.form.patient_id}`;
      } else {
        route = "doctors";
      }
      let response = await module.setDoctorTreeSelect(route);
      // Success
      if (response.state == "success") {
        // Set Option Select
        this.doctors = response.data;
      }
    },

    async getMedicalRecord(id) {
      this.attachment = [];
      this.displayImage = [];
      this.displayAttachment = [];
      let response = await module.get("medical-records/" + id);

      if (response) {
        if (response.attachment != null) {
          let attach = response.attachment.split(",");

          for (let a = 0; a < attach.length; a++) {
            let dataAttach = attach[a].split(".");

            if (dataAttach.length > 1) {
              if (dataAttach[dataAttach.length - 1] === "jpg") {
                this.displayImage.push(attach[a]);
              } else if (dataAttach[dataAttach.length - 1] === "png") {
                this.displayImage.push(attach[a]);
              } else if (dataAttach[dataAttach.length - 1] === "jpeg") {
                this.displayImage.push(attach[a]);
              } else if (dataAttach[dataAttach.length - 1] === "bmp") {
                this.displayImage.push(attach[a]);
              } else if (dataAttach[dataAttach.length - 1] === "pdf") {
                this.displayAttachment.push(attach[a]);
              } else if (dataAttach[dataAttach.length - 1] === "xlsx") {
                this.displayAttachment.push(attach[a]);
              } else if (dataAttach[dataAttach.length - 1] === "csv") {
                this.displayAttachment.push(attach[a]);
              } else if (dataAttach[dataAttach.length - 1] === "docx") {
                this.displayAttachment.push(attach[a]);
              } else if (dataAttach[dataAttach.length - 1] === "xls") {
                this.displayAttachment.push(attach[a]);
              } else if (dataAttach[dataAttach.length - 1] === "zip") {
                this.displayAttachment.push(attach[a]);
              } else if (dataAttach[dataAttach.length - 1] === "rar") {
                this.displayAttachment.push(attach[a]);
              } else if (dataAttach[dataAttach.length - 1] === "txt") {
                this.displayAttachment.push(attach[a]);
              } else {
                this.displayImage.push(attach[a]);
              }
            } else {
              this.displayImage.push(attach[a]);
            }
          }

          this.attachment = attach;
        }
        this.edit = response;
        this.detail = response;
        if (this.detail.time == "00:00") {
          this.detail.display_date = this.detail.dateCondition;
        }

        this.dataLoad = true;
        let dateTime = response.date.split(" ");

        this.date = response.date;
        this.time = dateTime[1];
        this.display.patient_name = `${response.patient_name} (${response.patient_unique_id})`;
        console.log("date", this.date);

        this.form.doctor_id = response.doctor_id;
        this.form.doctor_start_time = response.doctor_start_time;
        this.form.doctor_end_time = response.doctor_end_time;

        this.edit.doctor_id.forEach((element, index) => {
          let data = {
            id: element,
            name: response.doctor_name[index],
            start_time:
              response.doctor_start_time[index] == undefined
                ? "00:00:00"
                : response.doctor_start_time[index],
            end_time:
              response.doctor_end_time[index] == undefined
                ? "00:00:00"
                : response.doctor_end_time[index],
          };
          let clone = { ...data };
          this.doctorCollection.push(data);
        });

        if (this.form.doctor_start_time.length == 0) {
          this.edit.doctor_id.forEach((element, index) => {
            this.form.doctor_start_time.push("00:00:00");
            this.form.doctor_end_time.push("00:00:00");
          });
        }

        if (
          this.currentUser.user_type_id == 1 &&
          !this.edit.title.includes(this.currentUser.name)
        ) {
          this.edit.title += `<br><p>${this.currentUser.name}</p>`;
          this.edit.case += `<br><p>${this.currentUser.name}</p>`;
        }

        if (
          this.currentUser.hasOwnProperty("department_id") &&
          this.currentUser.department_id == 1 &&
          !this.edit.title.includes(this.currentUser.name)
        ) {
          this.edit.title += `<p>${this.currentUser.name}</p>`;
          console.log("ini masuok");
        } else {
          console.log("ini gak masuok");
        }

        console.log("this.form.doctor_id", this.form.doctor_id);
        console.log("this.form.doctor_start_time", this.form.doctor_start_time);
        console.log("this.form.doctor_end_time", this.form.doctor_end_time);
      }
    },

    async getDocument(id) {
      this.list = [];
      this.attachments = [];
      this.fileNameDetail = [];
      let listData = await module.get(
        "documents/" + id + "/" + this.allDayDetail
      );
      this.list = listData[0];

      this.attachments = listData[0].files.split(",");

      let a;
      for (a = 0; a <= this.attachments.length - 1; a++) {
        let data = this.attachments[a].split("#");
        this.fileNameDetail.push(data[2].split("_").join(" "));
      }

      this.dataLoaded = true;
    },

    upload(event) {
      if (event.target.files.length > 0) {
        this.formData = new FormData();
      }

      for (let a = 0; a <= event.target.files.length; a++) {
        this.formData.append("attachment[]", event.target.files[a]);
      }
    },

    async formAdd() {
      if (this.currentUser.user_type_id == 1) {
        this.doctorCheckedValidation();
      }
      if (this.date != "" && this.date != null) {
        //this.form.date = this.date + " " + this.time;
        this.form.date = this.date;
      } else {
        this.form.date = "";
      }

      for (const [key, value] of Object.entries(this.form)) {
        let val;
        if (
          key == "doctor_id" ||
          key == "doctor_start_time" ||
          key == "doctor_end_time"
        ) {
          val = JSON.stringify(value);
        } else {
          val = value;
        }
        this.formData.append(key, val);
      }

      console.log("form", this.form);
      console.log("formData", this.formData);
      // Make Request
      console.log(this.formData);
      let response = await module.submit(this.formData, "medical-records");
      // Check Response
      if (response.state == "error") {
        // Validation Error
        if (response.error.status_code == 500) {
          this.error = validation.setValidationError(
            this.error,
            response.error.errors,
            "Harap hubungi Tim Wellmagic dan refresh halaman dengan menekan ctrl + f5"
          );
        } else {
          this.error = validation.setValidationError(
            this.error,
            response.error.errors
          );
        }

        console.log("resp in form====================", response);
        if (response.error.status_code == 500) {
          console.log("ini dijalankan");
          let formErrorLog = {
            endpoint: "medical-records",
            method: "post",
            status_code: response.error.status_code,
            message: response.error.message,
            data: JSON.stringify(this.form),
            user_id: this.currentUser.id,
            user_name: this.currentUser.name,
          };

          let submitLog = module.submitWithoutAlert(formErrorLog, "error-logs");
          console.log("submitLog", submitLog);
        }
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, "success");
        this.date = "";
        this.time = "";
        this.form.case = "";
        this.form.appointment_id = "";
        this.form.title = "";
        if (this.currentUser.user_type_id == 1) {
          this.addCkEditor = false;
          this.form.title = `<p>${this.currentUser.name}</p>`;
          this.addCkEditor = true;
        }
        if (
          this.currentUser.hasOwnProperty("department_id") &&
          this.currentUser.department_id == 1
        ) {
          this.addCkEditor = false;
          this.form.title = `<p>${this.currentUser.name}</p>`;
          this.addCkEditor = true;
        }
        this.form.patient_id = "";
        this.form.doctor_id = [];
        this.form.doctor_start_time = [];
        this.form.doctor_end_time = [];
        this.doctorCollection = [];
        this.error = validation.clearForm(this.error);
        this.handleList();
        this.formData = new FormData();
        this.display.patient_name = "";
        this.patientXDiagnose = [];
      }
    },

    async formEdit(id) {
      if (this.currentUser.user_type_id == 1) {
        this.doctorCheckedValidation();
      }
      // implode attachment
      let dataPush = [];
      for (let a = 0; a < this.displayImage.length; a++) {
        dataPush.push(this.displayImage[a]);
      }
      for (let b = 0; b < this.displayAttachment.length; b++) {
        dataPush.push(this.displayAttachment[b]);
      }

      this.attachment = dataPush;

      if (this.date != "" && this.time != "") {
        // this.edit.date = this.date + " " + this.time;
        this.edit.date = this.date;
      }
      let attachments = this.attachment.join();

      this.formData.append("_method", "PUT");
      for (const [key, value] of Object.entries(this.edit)) {
        let val;
        if (
          key == "doctor_id" ||
          key == "doctor_start_time" ||
          key == "doctor_end_time"
        ) {
          // val = JSON.stringify(value);
        } else {
          val = value;
        }
        this.formData.append(key, val);
        // this.formData.append(key, value)
      }

      this.formData.append("attachment_list", attachments);
      this.formData.append("doctor_id", JSON.stringify(this.form.doctor_id));
      this.formData.append(
        "doctor_start_time",
        JSON.stringify(this.form.doctor_start_time)
      );
      this.formData.append(
        "doctor_end_time",
        JSON.stringify(this.form.doctor_end_time)
      );
      // Make Request
      let response = await module.submit(
        this.formData,
        "medical-records/" + id
      );
      // Check Response
      if (response.state == "error") {
        // Validation Error
        if (response.error.status_code == 500) {
          this.error = validation.setValidationError(
            this.error,
            response.error.errors,
            "Harap hubungi Tim Wellmagic dan refresh halaman dengan menekan ctrl + f5"
          );
        } else {
          this.error = validation.setValidationError(
            this.error,
            response.error.errors
          );
        }

        console.log("resp in form====================", response);
        if (response.error.status_code == 500) {
          console.log("ini dijalankan");
          let formErrorLog = {
            endpoint: "medical-records/" + id,
            method: "post",
            status_code: response.error.status_code,
            message: response.error.message,
            data: JSON.stringify(this.edit),
            user_id: this.currentUser.id,
            user_name: this.currentUser.name,
          };

          let submitLog = module.submitWithoutAlert(formErrorLog, "error-logs");
          console.log("submitLog", submitLog);
        }
      } else {
        // Success
        Swal.fire(response.success.title, response.success.message, "success");
        this.edit = [];
        this.attachment = [];
        this.handleList();
        this.handleAdd();
        this.formData = new FormData();
        this.patientXDiagnose = [];
        this.form.doctor_id = [];
        this.form.doctor_start_time = [];
        this.form.doctor_end_time = [];
        this.doctorCollection = [];
      }
    },

    doctorCheckedValidation() {
      let status = false;
      this.form.doctor_id.forEach((element, index) => {
        if (
          element == this.currentUser.id &&
          this.form.doctor_start_time[index] != "" &&
          this.form.doctor_end_time[index] != ""
        ) {
          console.log("doctor sudah input");
          this.doctorCollectionError.choose = "";
          status = true;
          return;
        }
      });
      if (status) return;
      Swal.fire({
        title: "Gagal",
        text: "Tambahkan nama anda di form pilih dokter",
        icon: "error",
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      });
      this.doctorCollectionError.choose =
        "Harap isi nama Anda dalam daftar dokter dan jangan lupa isi jam tindakan";
      throw new Error("validation error");
    },

    // draw
    undo() {
      this.$refs.signaturePad.undoSignature();
    },

    async save() {
      // implode attachment
      let dataPush = [];
      for (let a = 0; a < this.displayImage.length; a++) {
        dataPush.push(this.displayImage[a]);
      }
      for (let b = 0; b < this.displayAttachment.length; b++) {
        dataPush.push(this.displayAttachment[b]);
      }

      this.attachment = dataPush;

      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      // this.attachment = data
      this.formData.append("attachment[]", this.dataURItoBlob(data));

      if (isEmpty == false) {
        let attachments;

        if (this.attachment.length > 0) {
          attachments = this.attachment;
        } else {
          attachments = this.attachment.join();
        }

        this.formData.append("_method", "PUT");
        this.formData.append("attachment_list", attachments);
        for (const [key, value] of Object.entries(this.edit)) {
          let val;
          if (
            key == "doctor_id" ||
            key == "doctor_start_time" ||
            key == "doctor_end_time"
          ) {
            // val = JSON.stringify(value);
          } else {
            val = value;
          }
          this.formData.append(key, val);
          // this.formData.append(key, value)
        }

        this.formData.append("attachment_list", attachments);
        this.formData.append("doctor_id", JSON.stringify(this.form.doctor_id));
        this.formData.append(
          "doctor_start_time",
          JSON.stringify(this.form.doctor_start_time)
        );
        this.formData.append(
          "doctor_end_time",
          JSON.stringify(this.form.doctor_end_time)
        );
        console.log("this.form", this.form);
        console.log("this.edit", this.edit);
        console.log("this.formData", this.formData);
        // throw new Error("error stop");
        // Make Request
        let response = await module.submit(
          this.formData,
          "medical-records/" + this.edit.id
        );
        // Check Response
        if (response.state == "error") {
          // Validation Error
          this.error = validation.setValidationError(
            this.error,
            response.error.errors
          );
        } else {
          // Success
          Swal.fire(
            response.success.title,
            response.success.message,
            "success"
          );
          //  this.$bvModal.hide("modal-drawing");
          this.handleEdit(this.edit.id);
          this.formData = new FormData();
        }
      } else {
        Swal.fire(
          "Gagal Menyimpan Gambar",
          "Area Gambar Masih Kosong",
          "error"
        );
      }
    },

    // async getMedicalRecord() {
    //   let response = await module.get("medical-records/" + this.detail.id);

    //   if (response) {
    //     let attach = response.attachment.split(",");
    //     this.attachment = attach;
    //   }
    // },

    // convert base64 to image
    dataURItoBlob(dataURI) {
      var byteString;
      if (dataURI.split(",")[0].indexOf("base64") >= 0)
        byteString = atob(dataURI.split(",")[1]);
      else byteString = unescape(dataURI.split(",")[1]);

      // separate out the mime component
      var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

      // write the bytes of the string to a typed array
      var ia = new Uint8Array(byteString.length);
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }

      return new Blob([ia], {
        type: mimeString,
      });
    },

    // access management
    async setActiveMenu() {
      let access_right_user = window.localStorage.getItem(
        "access_right_display"
      );
      let access_right = JSON.parse(access_right_user);
      let a;
      for (a = 0; a < access_right.length; a++) {
        if (access_right[a] == "3006") {
          this.manipulateBtn = true;
        }
      }
    },

    // filter
    resetFilter() {
      // this.filter.start_date = moment().startOf('day').format('YYYY-MM-DD HH:mm:ss')
      // this.filter.end_date = moment().endOf('day').format('YYYY-MM-DD HH:mm:ss')
      this.filter = validation.clearForm(this.filter);
      this.filter.diagnose_id = [];
      this.btnActiveOnClick(this.lastPeriodType);
      this.list();
    },

    // pagination
    async pageOnClick(evt, page) {
      evt.preventDefault();
      this.currentPage = page;
      await this.list();
    },

    btnPeriodeOnClick() {
      if (this.onShowPeriod == false) {
        this.onShowPeriod = true;
        this.btnActiveOnClick("period");
      } else {
        this.onShowPeriod = false;
        this.btnActiveOnClick("period");
      }
    },

    btnActiveOnClick(type) {
      if (type == "days") {
        // set time
        this.filter.start_date = moment()
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        this.filter.end_date = moment()
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");

        // get data
        this.list();

        // set btn Active
        this.btnActive.days = true;
        this.btnActive.month = false;
        this.btnActive.lastMonth = false;
        this.btnActive.year = false;
        this.btnActive.period = false;

        this.lastPeriodType = "days";

        // hide period
        this.onShowPeriod = false;
      } else if (type == "month") {
        // set time
        this.filter.start_date = moment()
          .startOf("month")
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        this.filter.end_date = moment()
          .endOf("month")
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");

        // get data
        this.list();

        // set btn Active
        this.btnActive.days = false;
        this.btnActive.month = true;
        this.btnActive.lastMonth = false;
        this.btnActive.year = false;
        this.btnActive.period = false;

        this.lastPeriodType = "month";

        // hide period
        this.onShowPeriod = false;
      } else if (type == "lastMonth") {
        // set time
        this.filter.start_date = moment()
          .subtract(1, "months")
          .startOf("month")
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        this.filter.end_date = moment()
          .subtract(1, "months")
          .endOf("month")
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");

        // get data
        this.list();

        // set btn Active
        this.btnActive.days = false;
        this.btnActive.month = false;
        this.btnActive.lastMonth = true;
        this.btnActive.year = false;
        this.btnActive.period = false;

        this.lastPeriodType = "lastMonth";

        // hide period
        this.onShowPeriod = false;
      } else if (type == "year") {
        // set time
        this.filter.start_date = moment()
          .startOf("year")
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss");
        this.filter.end_date = moment()
          .endOf("year")
          .endOf("day")
          .format("YYYY-MM-DD HH:mm:ss");

        // get data
        this.list();

        // set btn Active
        this.btnActive.days = false;
        this.btnActive.month = false;
        this.btnActive.lastMonth = false;
        this.btnActive.year = true;
        this.btnActive.period = false;

        this.lastPeriodType = "year";

        // hide period
        this.onShowPeriod = false;
      } else if (type == "period") {
        // set time
        this.filter.start_date = "";
        this.filter.end_date = "";

        // set btn Active
        this.btnActive.days = false;
        this.btnActive.month = false;
        this.btnActive.lastMonth = false;
        this.btnActive.year = false;
        this.btnActive.period = true;
      }

      this.$root.$emit("setFilterDataOnClick", this.filter);
    },

    async checkMedicalRecordData() {
      console.log(
        "=================================================================== checkMedicalRecordData"
      );
      this.form.patient_id = this.$route.query.patient_id;
      this.getRecenly();

      // update form choosen model
      this.getPatientXDiagnose(this.form.patient_id);
      this.getClassificationPatient(this.form.patient_id);

      // trigger update data modal
      this.$root.$emit(
        "updateModalPatientXDiagnoseOnSetPatient",
        this.form.patient_id
      );
      this.$root.$emit(
        "updateModalClassificationPatientOnSetPatient",
        this.form.patient_id
      );

      let medicalRecord = await module.get(
        `medical-records/today-appointment/${this.$route.query.patient_id}`
      );
      console.log(
        "medicalRecord ===============================================================================",
        medicalRecord
      );
      if (medicalRecord != null) {
        this.handleEdit(medicalRecord.id);
      }

      let patient = await module.get(`patients/${this.form.patient_id}`);
      if (patient != null) {
        console.log("patient", patient);
        if (this.currentUser.user_type_id != 1) {
          this.filterModalDoctorMultiple.ids = patient.doctor_in_charge;
        }
      }
    },
  },

  watch: {
    "form.patient_id": function (newVal) {
      if (this.currentUser.user_type_id != 1) {
        // this.form.doctor_id = [];
        console.log("ini yang bikin form doctor kosong", this.form);
        // this.getDoctorsOption();
      }
    },
    "edit.patient_id": function (newVal) {
      if (this.currentUser.user_type_id != 1) {
        // this.getDoctorsOption();
      }
    },
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Pasien" },
      { title: "Rekam Medis" },
    ]);
    this.list();
    // get diagnose items filter
    this.getTreeselectDiagnose();
    // empty diagnose
    this.patientXDiagnose = [];
    // Get Doctors
    this.getDoctorsOption();
    if (
      this.currentUser.hasOwnProperty("department_id") &&
      this.currentUser.department_id == 1
    ) {
      this.form.title += `<p>${this.currentUser.name}</p>`;
    }
    if (this.currentUser.user_type_id == 1) {
      // this.form.doctor_id = this.currentUser.id;
      this.form.title += `<p>${this.currentUser.name}</p>`;
      this.form.case += `<p>${this.currentUser.name}</p>`;
      this.addCkEditor = true;
      this.addCkEditorCase = true;
      console.log("form", this.form);
      if (this.currentUser.user_type_id == 1) {
        this.filterModalDoctorMultiple.ids = [this.currentUser.id];
      }
    } else {
      this.addCkEditor = true;
      this.addCkEditorCase = true;
    }

    if (
      this.$route.query.hasOwnProperty("from") &&
      this.$route.query.from == "today_appointment"
    ) {
      this.checkMedicalRecordData();
    }
    // if(this.$route.query)
    // this.getClassification();
    this.setActiveMenu();
  },
};
</script>

<style scoped>
/* CK Editor */

#signature {
  border: double 3px transparent;
  border-radius: 5px;
  background-image: linear-gradient(white, white),
    radial-gradient(circle at top left, #4bc5e8, #9f6274);
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.modal-dialog.modal-sm {
  max-width: 1450px;
}

.document-editor {
  border: 1px solid var(--ck-color-base-border);
  border-radius: var(--ck-border-radius);

  /* Set vertical boundaries for the document editor. */
  max-height: 700px;

  /* This element is a flex container for easier rendering. */
  display: flex;
  flex-flow: column nowrap;
}

.document-editor__toolbar {
  /* Make sure the toolbar container is always above the editable. */
  z-index: 1;

  /* Create the illusion of the toolbar floating over the editable. */
  box-shadow: 0 0 5px hsla(0, 0%, 0%, 0.2);

  /* Use the CKEditor CSS variables to keep the UI consistent. */
  border-bottom: 1px solid var(--ck-color-toolbar-border);
}

.document-editor__toolbar .ck-toolbar {
  border: 0;
  border-radius: 0;
}

.document-editor__editable-container {
  padding: calc(2 * var(--ck-spacing-large));
  background: var(--ck-color-base-foreground);

  /* Make it possible to scroll the "page" of the edited content. */
  overflow-y: scroll;
}

.document-editor__editable-container .ck-editor__editable {
  /* Set the dimensions of the "page". */
  width: 15.8cm;
  min-height: 21cm;

  /* Keep the "page" off the boundaries of the container. */
  padding: 1cm 2cm 2cm;

  border: 1px hsl(0, 0%, 82.7%) solid;
  border-radius: var(--ck-border-radius);
  background: white;

  /* The "page" should cast a slight shadow (3D illusion). */
  box-shadow: 0 0 5px hsla(0, 0%, 0%, 0.1);

  /* Center the "page". */
  margin: 0 auto;
}

.document-editor .ck-content,
.document-editor .ck-heading-dropdown .ck-list .ck-button__label {
  font: 16px/1.6 "Helvetica Neue", Helvetica, Arial, sans-serif;
}

/* Adjust the headings dropdown to host some larger heading styles. */
.document-editor .ck-heading-dropdown .ck-list .ck-button__label {
  line-height: calc(
    1.7 * var(--ck-line-height-base) * var(--ck-font-size-base)
  );
  min-width: 6em;
}

/* Scale down all heading previews because they are way too big to be presented in the UI.
Preserve the relative scale, though. */
.document-editor
  .ck-heading-dropdown
  .ck-list
  .ck-button:not(.ck-heading_paragraph)
  .ck-button__label {
  transform: scale(0.8);
  transform-origin: left;
}

/* Set the styles for "Heading 1". */
.document-editor .ck-content h2,
.document-editor .ck-heading-dropdown .ck-heading_heading1 .ck-button__label {
  font-size: 2.18em;
  font-weight: normal;
}

.document-editor .ck-content h2 {
  line-height: 1.37em;
  padding-top: 0.342em;
  margin-bottom: 0.142em;
}

/* Set the styles for "Heading 2". */
.document-editor .ck-content h3,
.document-editor .ck-heading-dropdown .ck-heading_heading2 .ck-button__label {
  font-size: 1.75em;
  font-weight: normal;
  color: hsl(203, 100%, 50%);
}

.document-editor
  .ck-heading-dropdown
  .ck-heading_heading2.ck-on
  .ck-button__label {
  color: var(--ck-color-list-button-on-text);
}

/* Set the styles for "Heading 2". */
.document-editor .ck-content h3 {
  line-height: 1.86em;
  padding-top: 0.171em;
  margin-bottom: 0.357em;
}

/* Set the styles for "Heading 3". */
.document-editor .ck-content h4,
.document-editor .ck-heading-dropdown .ck-heading_heading3 .ck-button__label {
  font-size: 1.31em;
  font-weight: bold;
}

.document-editor .ck-content h4 {
  line-height: 1.24em;
  padding-top: 0.286em;
  margin-bottom: 0.952em;
}

/* Set the styles for "Paragraph". */
.document-editor .ck-content p {
  font-size: 1em;
  line-height: 1.63em;
  padding-top: 0.5em;
  margin-bottom: 1.13em;
}

/* Make the block quoted text serif with some additional spacing. */
.document-editor .ck-content blockquote {
  font-family: Georgia, serif;
  margin-left: calc(2 * var(--ck-spacing-large));
  margin-right: calc(2 * var(--ck-spacing-large));
}
</style>
