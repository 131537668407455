<template>
  <div>
    <b-modal
      id="modal-doctor-multiple"
      size="xl"
      title="Pilih Dokter"
      hide-footer
    >
      <Table
        purpose="modal-doctor-multiple"
        @unCheckDataMultiple="unCheckData"
        @checkDataMultiple="checkData"
        :doctorCollection="dataCollection"
        :filterModalDoctorMultiple="filterModalDoctorMultiple"
      />
    </b-modal>
  </div>
</template>

<script>
import Table from "@/component/doctors/Table.vue";

export default {
  props: {
    dataCollection: Array,
    filterModalDoctorMultiple: Object,
  },

  components: {
    Table,
  },

  methods: {
    checkData(value) {
      this.$emit("checkData", value);
    },
    unCheckData(value) {
      this.$emit("unCheckData", value);
    },
  },
};
</script>

<style>
</style>
